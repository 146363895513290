import React, { useEffect, useState } from "react";

export const TranslaterContext = React.createContext();

function TranslatorProvider({ children }) {
  const [translaterOpen, setTranslaterOpen] = useState(false);

  useEffect(() => {
    var mutationObserver = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (
          mutation.target?.style?.cssText?.includes(
            "position: relative; min-height: 100%; top: 40px;"
          )
        ) {
          setTranslaterOpen(true);
        } else {
          setTranslaterOpen(false);
        }
      });
    });
    mutationObserver.observe(document.body, {
      attributes: true,
    });
    return () => {
      mutationObserver.disconnect();
    };
  }, []);

  const value = React.useMemo(() => {
    return {
      translaterOpen,
    };
  }, [translaterOpen]);

  return (
    <TranslaterContext.Provider value={value}>
      {children}
    </TranslaterContext.Provider>
  );
}

export default TranslatorProvider;
