import React from "react";
import { Center } from "../../../styles/component/style";
import { SignupContainer } from "./Styles";
import { Signup } from "../../common/Signup/Signup";
import { Header } from "../../internal/Header/Header";
import useIfLoggedIn from "../../../hooks/useIfLoggedIn";
import labels from "../../../helpers/labels.json";
import { SignupwithGoogle } from "../../common/Signup/SignUpWithGoogle";

const Index = ({ location = {}, isGoogleSignUp = false }) => {
  useIfLoggedIn();

  const inputs = [
    {
      name: "fullname",
      label: labels["onboarding.fullname"],
      type: "text",
      required: true,
    },
    {
      name: "email",
      label: labels["user.email"],
      type: "email",
      required: true,
    },
    {
      name: "mobile",
      label: labels["user.mobile"],
      type: "Number",
      required: true,
    },
    {
      name: "password",
      label: labels["onboarding.password"],
      type: "password",
      required: true,
    },
    // {
    //   name: "confirmPassword",
    //   label: labels["onboarding.confirm_password"],
    //   type: "password",
    //   required: true,
    // },
  ];

  return (
    <Header>
      <Center>
        <SignupContainer>
          {isGoogleSignUp ? (
            <SignupwithGoogle
              heading={labels["onboarding.enter_phone_number"]}
              inputs={inputs.filter((el) => el.label === labels["user.mobile"])}
              tcLink="http://localhost:3000/terms-and-conditions"
              loginLink={"http://localhost:3000/signup"}
              location={location}
            />
          ) : (
            <Signup
              heading={labels["onboarding.step1"]}
              inputs={inputs}
              tcLink="http://localhost:3000/terms-and-conditions"
              loginLink={"http://localhost:3000/signup"}
              location={location}
            />
          )}
        </SignupContainer>
      </Center>
    </Header>
  );
};

export default Index;
