import React from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import VideoPlayer from "../Video";

const Index = ({ styledBtn, boxSize, label = "Watch Tutorial", src }) => {
  return (
    <VideoWrapper style={boxSize}>
      {src && <VideoPlayer src={src} />}
    </VideoWrapper>
  );
};

export default Index;

export const VideoWrapper = styled.div`
  background: ${COLORS.PRIMARY_WHITE};
  width: 423.8px;
  max-height: 238px;
  /* padding: 32px; */
  margin-right: 60.2px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
