import { useContext } from "react";
import { DictationXContext } from "../providers/OrderDictationXProvider";

export default function useXDictation() {
  const context = useContext(DictationXContext);
  if (context === undefined) {
    throw new Error("useCartCount must be used within a CartCountProvider");
  }
  return context;
}
