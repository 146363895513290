import React from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { PrimaryCTAButton } from "../../common/Buttons";

const Index = ({
  heading = "Enter Heading",
  StyledPara,
  StyleCard,
  terms = [
    {
      snumber: "Term 1",
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      snumber: "Term 2",
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
  ],

  button = false,
  buttonOnClick = () => {},
}) => {
  return (
    <CardWrapper style={StyleCard}>
      <FlexBetween>
        <CardHeader>{heading}</CardHeader>
        {button && (
          <WrapperButton>
            <PrimaryCTAButton
              onClick={buttonOnClick}
              style={{ padding: "unset", height: 35 }}
            >
              {button}
            </PrimaryCTAButton>
          </WrapperButton>
        )}
      </FlexBetween>
      {terms.map((term, index) => (
        <div key={index}>
          <Title>{term.snumber}</Title>
          <Para style={StyledPara}>{term.text}</Para>
        </div>
      ))}
    </CardWrapper>
  );
};

export default Index;

export const CardWrapper = styled.div`
  background-color: ${COLORS.PRIMARY_WHITE};
  width: 618px;
  padding: 0 44px 30px 19px;
  border-radius: 11px;
  border: solid 0.5px #e4e5ef;
`;
export const CardHeader = styled.h2`
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
`;

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 20px;
`;

export const Title = styled.h3`
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 14px;
  font-weight: 600;
  height: 19px;
`;

export const Para = styled.p`
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 12px;
  font-weight: 300;
  line-height: 1.42;
  margin-bottom: 10px;
  margin-top: 5px;
  /* height:64px; */
`;
const WrapperButton = styled.div`
  width: 146px;
`;
