import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import Drawer from "../../common/Drawer";
import { Heading } from "../../../styles/component/style";
import CustomTable from "../../common/CustomTable/CustomTable";
import CaseService from "../../../services/CaseService";
import AgreementDrawer from "./AgreementDrawer";
import moment from "moment";
import queryString from "query-string";
import { getErrorMessage, parseTimeStamp } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import { makeStyles, Tooltip } from "@material-ui/core";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HttpsIcon from "@material-ui/icons/Https";
import PublicIcon from "@material-ui/icons/Public";
import DrawerRecording from "../ViewRecording/ViewRecording";
import DocTypeChange from "../DocumentTypeChange/DocTypeChange";
import HearingBundles from "../HearingBundles/index";
import HttpsLockOpen from "@material-ui/icons/LockOpen";
import DocVisibilityTypeChange from "../DocVisibilityTypeChange/index";
import DocViewers from "./DocViewer";
import ViewKeywords from "./ViewExtract";
import WhatsAppIcon from "./component/WhatsAppIcon";
import AddDocument from "./AddDocument";

const docTypes = [
  { ownerType: "claimant", title: "Claimant Documents" },
  { ownerType: "respondent", title: "Respondent Documents" },
  { ownerType: "common", title: "Neutral’s documents" },
];

async function getCaseDocument(id, query) {
  try {
    const response = await CaseService.getcaseDocuments(id, query);
    return { ...response };
  } catch (error) {
    throw error;
  }
}

const Index = ({
  state,
  setState,
  id,
  type,
  claimantId,
  respondentId,
  agreementsAndAwards,
  isActionsDisabled,
  caseDetails,
  title,
}) => {
  return (
    <Drawer state={state} setState={setState} label="Drawer">
      <Table
        {...{
          setState,
          id,
          type,
          claimantId,
          respondentId,
          agreementsAndAwards,
          isActionsDisabled,
          caseDetails,
          title,
        }}
      />
    </Drawer>
  );
};

export default Index;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#f9f9f9",
    border: "solid 0.5px #e1e3ee",
    boxShadow: "none",
    margin: "16px 0px 0px !important",
    "& .MuiExpansionPanelSummary-content": {
      margin: "27px 0 27px 24px !important",
    },
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 15,
      backgroundColor: "#fff",
    },
    "& .MuiSvgIcon-root": {
      fill: "#00838c",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Table = ({
  setState,
  id,
  type,
  claimantId,
  respondentId,
  agreementsAndAwards,
  isActionsDisabled,
  caseDetails,
  title,
}) => {
  const [documents, setDocuments] = useState();
  const [trigger, setTrigger] = useState(false);
  const [urls, setUrls] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleDownload = async () => {
    try {
      const directoryHandle = await window.showDirectoryPicker();

      // Download each file to the selected directory
      for (const { url, filename } of urls) {
        const response = await fetch(url);
        const blob = await response.blob();
        await writeFile(directoryHandle, filename, blob);
      }
      enqueueSnackbar("Files downloaded successfully to selected folder.", {
        variant: "success",
      });
    } catch (err) {
      console.error("Error downloading files:", err);
    } finally {
      setTrigger(true);
      setUrls([]);
    }
  };

  const writeFile = async (directoryHandle, filename, blob) => {
    const fileHandle = await directoryHandle.getFileHandle(filename, {
      create: true,
    });
    const writable = await fileHandle.createWritable();
    await writable.write(blob);
    await writable.close();
  };

  return (
    <div>
      <Header>
        <Heading
          style={{
            marginBottom: "unset",
            display: "flex",
          }}
        >
          Case Documents{" "}
          <AddDocument
            {...{ id, caseDetails, isActionsDisabled, setTrigger }}
          />
          <WhatsAppIcon {...{ id, isActionsDisabled, title }} />
          {urls.length ? (
            <Card>
              <div className="add_circle" onClick={handleDownload}>
                <img
                  src={require("../../../assets/images/JustAct Icons Download-02.svg")}
                  alt="submit"
                />
              </div>
            </Card>
          ) : null}
        </Heading>
        <ImageWrapper>
          <img
            src={require("../../../assets/images/closeModal.svg")}
            onClick={() => {
              setState(false);
            }}
            alt="close"
          />
        </ImageWrapper>
      </Header>
      <AccordionContainer>
        {docTypes
          .filter((item) =>
            type === "negotiation" ? item.ownerType !== "mediator" : true,
          )
          .map((item) => (
            <TableWithAccordion
              {...item}
              {...{
                id,
                claimantId,
                respondentId,
                setState,
                setDocuments,
                trigger,
                setTrigger,
                setUrls,
                caseDetails,
              }}
            />
          ))}
        {id ? (
          <HearingBundles
            title="Hearing Bundles"
            {...{ id, setState, documents }}
          />
        ) : (
          ""
        )}
        {type !== "negotiation" ? (
          <DrawerRecording
            title="View Recordings"
            {...{ id, setState, type }}
          />
        ) : (
          ""
        )}
        {type !== "negotiation" ? (
          <AgreementDrawer
            title="Template Agreements and Awards"
            {...{ id, setState, type, agreementsAndAwards }}
          />
        ) : (
          ""
        )}
      </AccordionContainer>
    </div>
  );
};

function TableWithAccordion({
  id,
  title,
  ownerType,
  caseDetails,
  setDocuments,
  trigger,
  setTrigger,
  setUrls,
}) {
  const classes = useStyles();
  const [result, setResult] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [rowData, setDatas] = useState();
  const [modal, setModal] = useState(false);
  const [docview, setDocView] = useState({ state: false });
  const [view, setView] = useState({ state: false });
  const MTRef = useRef(null);

  useEffect(() => {
    if (trigger) {
      if (MTRef.current) {
        MTRef.current.onQueryChange(MTRef?.current?.state?.query);
        setTrigger(false);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    if (result) {
      setDocuments(result.total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const columns = [
    {
      field: "documentNo",
      title: "Doc ID",
      sorting: false,
      render: (rowData) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ width: 20 }}>
            {rowData?.visibilityType === "me" ? (
              ownerType === "common" && rowData?.ownerType === "mediator" ? (
                <Tooltip title="Private" placement="top">
                  <HttpsIcon
                    style={{
                      fontSize: 18,
                      fill: COLORS.COLOR_DARK,
                      position: "relative",
                      top: 2,
                      cursor: "pointer",
                    }}
                    onClick={() => setModal(true) || setDatas(rowData)}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Private" placement="top">
                  <HttpsIcon
                    style={{
                      fontSize: 18,
                      fill: COLORS.COLOR_DARK,
                      position: "relative",
                      top: 2,
                    }}
                  />
                </Tooltip>
              )
            ) : rowData?.visibilityType === "others" ? (
              ownerType === "common" && rowData?.ownerType === "mediator" ? (
                <Tooltip title="Shared with CM" placement="top">
                  <HttpsLockOpen
                    style={{
                      fontSize: 18,
                      fill: COLORS.COLOR_DARK,
                      position: "relative",
                      top: 2,
                      cursor: "pointer",
                    }}
                    onClick={() => setModal(true) || setDatas(rowData)}
                  />
                </Tooltip>
              ) : ownerType === "common" &&
                rowData?.ownerType === "caseManager" ? (
                <Tooltip title="Shared with Neutral" placement="top">
                  <HttpsLockOpen
                    style={{
                      fontSize: 18,
                      fill: COLORS.COLOR_DARK,
                      position: "relative",
                      top: 2,
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Shared with CM and Neutral" placement="top">
                  <HttpsLockOpen
                    style={{
                      fontSize: 18,
                      fill: COLORS.COLOR_DARK,
                      position: "relative",
                      top: 2,
                      // cursor: "pointer"
                    }}
                  />
                </Tooltip>
              )
            ) : ownerType === "common" && rowData?.ownerType === "mediator" ? (
              <Tooltip title="Public" placement="top">
                <PublicIcon
                  style={{
                    fontSize: 18,
                    fill: COLORS.COLOR_DARK,
                    position: "relative",
                    top: 2,
                    cursor: "pointer",
                  }}
                  onClick={() => setModal(true) || setDatas(rowData)}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Public" placement="top">
                <PublicIcon
                  style={{
                    fontSize: 18,
                    fill: COLORS.COLOR_DARK,
                    position: "relative",
                    top: 2,
                  }}
                />
              </Tooltip>
            )}
          </div>
          <div style={{ marginLeft: 10 }}>
            <HyperLink onClick={() => window.open(rowData.url?.url)}>
              {rowData.documentNo}
            </HyperLink>
          </div>
        </div>
      ),
      headerStyle: {
        padding: `12px 16px`,
      },
      cellStyle: {
        padding: `12px 16px`,
      },
      // headerStyle: {
      //   padding: `12px 16px 12px 40px`,
      // },
      // cellStyle: {
      //   padding: `12px 16px 12px 40px`,
      // },
    },
    {
      field: "url",
      title: "Document",
      sorting: false,
      render: (rowData) => (
        <Tooltip
          title={
            rowData?.url?.url?.split("/")[
              rowData?.url?.url?.split("/").length - 1
            ]
          }
          placement="top"
        >
          <HyperLink
            onClick={(event) => {
              event.preventDefault();
              setDocView({
                state: true,
                url: rowData?.url?.url,
                name: rowData?.name,
                title: rowData?.entity?.title,
                pdf: rowData?.name.toLowerCase().endsWith(".pdf"),
              });
            }}
            style={{
              width: 150,
            }}
          >
            {rowData?.name || "-"}
          </HyperLink>
          {/* <HyperLink
            onClick={() => window.open(rowData.url?.url, rowData?.name)}
            style={{ width: 150 }}
          >
            {rowData?.name || "-"}
          </HyperLink> */}
        </Tooltip>
      ),
      headerStyle: {
        padding: `12px 16px`,
      },
      cellStyle: {
        padding: `12px 16px`,
      },
    },
    {
      field: "owner.name",
      title: "Submitted By",
      sorting: false,
      headerStyle: {
        padding: `12px 16px`,
      },
      cellStyle: {
        padding: `12px 16px`,
      },
    },
    {
      field: "date",
      title: "Date",
      sorting: false,
      render: (rowData) => (
        <StyledTitle>
          <div>
            {rowData.created_at &&
              moment(parseTimeStamp(rowData.created_at)).format("DD/MM/YYYY")}
          </div>
          <div>
            {rowData.created_at &&
              moment(parseTimeStamp(rowData.created_at)).format("hh:mm A")}
          </div>
        </StyledTitle>
      ),
      headerStyle: {
        maxWidth: 80,
        width: 80,
        padding: `12px 16px`,
      },
      cellStyle: {
        maxWidth: 80,
        width: 80,
        padding: `12px 16px`,
      },
    },
    {
      field: "type",
      title: "Document Type",
      sorting: false,
      render: (rowData) =>
        rowData?.entity?.resolutionKind === "arbitration" ? (
          <DocTypeChange
            {...{ rowData, MTRef }}
            documentTypes={caseDetails?.documentCategoryandTypes}
          />
        ) : (
          <StyledTitle style={{ paddingLeft: "25px" }}>
            {rowData?.url?.uploadType || "other"}
          </StyledTitle>
        ),
      headerStyle: {
        maxWidth: 190,
        width: 190,
        padding: `12px 16px`,
      },
      cellStyle: {
        maxWidth: 190,
        width: 190,
        padding: `12px 16px`,
      },
    },
    {
      field: "extract",
      title: "Extract",
      sorting: false,
      render: (rowData) =>
        rowData?.keywords?.length || rowData?.documentSummary ? (
          <HyperLink
            onClick={() =>
              setView({
                state: true,
                keywords: rowData?.keywords,
                documentSummary: rowData?.documentSummary,
              })
            }
          >
            {"View"}
          </HyperLink>
        ) : (
          <div style={{ paddingLeft: 12 }}>{"-"}</div>
        ),
      headerStyle: {
        padding: `12px 16px`,
      },
      cellStyle: {
        paddingLeft: 18,
      },
    },
  ];

  const data = (query) =>
    new Promise((resolve) => {
      let payload = {
        page: query.page + 1,
        perPage: 10,
      };
      if (ownerType) {
        if (ownerType === "claimant") {
          payload.claimantView = true;
        } else if (ownerType === "respondent") {
          payload.respondentView = true;
        } else {
          payload.commonView = true;
        }
      }
      const stringParams = `?${queryString.stringify(payload)}`;
      getCaseDocument(id, stringParams)
        .then(async (result) => {
          if (result) {
            resolve({
              data: result.data,
            });
            setResult(result);
          }
        })
        .catch((error) => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error",
          });
          setResult({ data: [], lastPage: 1, page: 1, perPage: 10, total: 0 });
          resolve({
            data: [],
            page: 0,
            total: 0,
          });
        });
    });

  return (
    <>
      <Accordion className={classes.root}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <AccordionHeading>
            {title} ({result?.total})
          </AccordionHeading>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer>
            <CustomTable
              selection
              onSelectionChange={(selected) =>
                setUrls(
                  selected.map((item) => {
                    return {
                      url: item?.url?.url,
                      filename: item?.name,
                    };
                  }),
                )
              }
              pluralTitle="Documents"
              singularTitle=""
              {...{
                columns,
                data,
                MTRef,
              }}
              noToolbar
              pageSize={10}
              hidePagination={result.lastPage === 1}
              state={result}
            />
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <DocVisibilityTypeChange
        {...{
          modal,
          setModal,
          rowData,
          ownerType,
          id,
          MTRef,
        }}
      />
      <ViewKeywords {...{ view, setView }} />
      <DocViewers {...{ docview, setDocView }} />
    </>
  );
}

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 19px 40px;
  margin-top: 15px;
  border-bottom: 1px solid ${COLORS.BORDER_GREY};
`;
export const TableContainer = styled.div`
  /* margin: 30px 29px auto 38px; */
  width: 100%;
`;
export const AccordionHeading = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
`;

export const AccordionContainer = styled.div`
  padding: 0 20px;
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
`;
export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledTitle = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  min-width: 100px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  margin: 0 auto;
  @media (min-width) {
    justify-content: space-between;
  }
`;

export const CheckedIconContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HyperLinkSpan = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-left: 25px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
`;

const Card = styled.div`
  width: 32px;
  display: flex;
  border-radius: 7px;
  align-items: center;
  justify-content: space-between;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
      & .add_circle {
        cursor: not-allowed !important;
      }
    `}

  & .add_circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 23px;
    height: 23px;
    border-radius: 100%;
    cursor: pointer;
    background-color: rgba(0, 131, 140, 0.13);
  }
  & .add_icon {
    color: ${COLORS.BTN_GREEN};
    font-size: 18px;
  }
`;
