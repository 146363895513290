import React, { useState } from "react";
import { CaseManagerCardData } from "../styles";
import { PrimaryCTAButton } from "../../../common/Buttons";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../helpers/functions";
import CaseService from "../../../../../src/services/CaseService";
import AlertDialog from "../../../common/Alert";
import _ from "lodash";
import theme from "../../../../assets/theme";
import COLORS from "../../../../assets/Colors";

export default function Index({
  type = "",
  id,
  isActionsDisabled = false,
  caseDetails,
  resolutionKind
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState({});

  async function getMeetingjoin(id) {
    try {
      const meetingurl = await CaseService.getMeeting(id);
      console.log(meetingurl);
      window.open(meetingurl);
      return;
      // return { ...meetingurl };
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setOpen(false);
    }
  }

  const joinMeetingAlert = (id, resolutionKind) => {
    setOpen(true);
    setDialogData({
      primaryBtnText: "Proceed",
      secondaryBtnText: "Cancel",
      clickSecondarybtn: () => setOpen(false),
      clickPrimaryBtn: () => getMeetingjoin(id),
      onDialogClose: () => setOpen(false),
      desc: (
        <>
          <b style={{ color: COLORS.LOGOUT_RED }}>
            We may record the meeting for case proceedings and transcription
            purposes
          </b>
        </>
      ),
      heading: `Join ${_.capitalize(resolutionKind)} Session`,
      descriptionTextStyle: {
        textAlign: "center",
        fontFamily: theme.fonts.primaryFontSemiBold
      }
    });
  };

  return (
    <>
      <CaseManagerCardData>
        <PrimaryCTAButton
          // disabled={
          //     isActionsDisabled
          //   }

          onClick={() => joinMeetingAlert(id, resolutionKind)}
        >
          {type}
        </PrimaryCTAButton>
      </CaseManagerCardData>
      <AlertDialog
        isOpen={open}
        {...{ ...dialogData }}
        descriptionTextStyle={{
          textAlign: "center",
          fontFamily: theme.fonts.primaryFontSemiBold
        }}
      />
    </>
  );
}
