import React, { useState, useEffect } from "react";
import { Modal, CircularProgress, Checkbox } from "@material-ui/core";
import CaseService from "../../../../services/CaseService";
import styled from "styled-components";
import theme from "../../../../assets/theme";
import COLORS from "../../../../assets/Colors";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../../common/Buttons";
import useLoader from "../../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../helpers/functions";
import { WhatsApp } from "@material-ui/icons";

const WhatsAppDoc = ({ id, modal, setModal, title }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedItem, setSelectedItem] = useState();
  const [state, setState] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (data?.length) {
      setSelectedItem(data.filter((item) => item?.selected));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (checked) {
      setUserDetails((prevData) => {
        // Use map to create a new array with the updated object
        return prevData.map((obj) => {
          return {
            ...obj,
            selected: true,
          };
        });
      });
    } else {
      setUserDetails((prevData) => {
        // Use map to create a new array with the updated object
        return prevData.map((obj) => {
          return {
            ...obj,
            selected: false,
          };
        });
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const response_data = await CaseService.getDocumentListAll(id);
        if (response_data) {
          setData(
            response_data?.documentList.map((item) => ({
              ...item,
              selected: false,
            }))
          );
        }
      } catch (error) {
        setLoading(false);
        throw error;
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const response = await CaseService.getPhoneNumbers(id);
        setUserDetails(
          response.caseUserDetails.map((item, index) => {
            return { ...item, id: index + 1, selected: false };
          })
        );
      } catch (error) {
        setLoading(false);
        throw error;
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  const onFormSubmit = async (id, selectedItem) => {
    try {
      setLoader({ state: true, message: "Sending..." });
      const payload = {
        caseTitle: title,
        documentList: selectedItem,
        caseUserDetails: userDetails.filter((item) => item?.selected),
      };
      const response = await CaseService.sendDocument(id, payload);
      if (response) {
        enqueueSnackbar(response.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setSelectedItem();
    }
  };

  return (
    <Modal
      open={modal}
      onClose={() => setModal(false)}
      disableRestoreFocus={true}
    >
      <DrawerContainer role="presentation" {...{ data }}>
        <Container>
          <HeadingContainer>
            <Heading>{"Send to WhatsApp"}</Heading>
            <CloseModal
              onClick={() => setModal(false)}
              src={require("../../../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          <FormContainer {...{ data }}>
            {loading ? (
              <div className="loader">
                <CircularProgress />
              </div>
            ) : state ? (
              <div className="docs-listing">
                <CheckboxContainer style={{ padding: "4px 5px 14px 5px" }}>
                  <CustomCheckbox
                    name={checked}
                    checked={checked}
                    onChange={(e) => setChecked(e?.target?.checked)}
                  />
                  <div className="users_name">
                    <div className="users_color">{"Select all agents"}</div>
                  </div>
                </CheckboxContainer>
                {userDetails?.length > 0 ? (
                  userDetails.map((item, index) => (
                    <StyledForm style={{ paddingBottom: "20px" }}>
                      <BodyContainer>
                        <CheckboxContainer style={{ marginTop: 6 }}>
                          <CustomCheckbox
                            onChange={(event) =>
                              setUserDetails((prevData) => {
                                // Use map to create a new array with the updated object
                                return prevData.map((obj) => {
                                  if (obj.id === item?.id) {
                                    // Use spread operator to copy the object and update the key
                                    return {
                                      ...obj,
                                      selected: event.target.checked,
                                    };
                                  }
                                  return obj; // Return the original object if not the one to be updated
                                });
                              })
                            }
                            checked={item?.selected}
                            name={item?.mobileNumber + index}
                            key={item.mobileNumber}
                          />

                          <div className="users_name">
                            <div>{item?.name}</div>
                            <div className="users_color">
                              ({item?.mobileNumber})
                            </div>
                            <div>({item?.role})</div>
                          </div>
                          {item?.selected && (
                            <>
                              <div className="add_circle">
                                <WhatsApp className="add_icon" />
                              </div>
                              <div className="document_name">
                                Send to WhatsApp
                              </div>
                            </>
                          )}
                        </CheckboxContainer>
                      </BodyContainer>
                    </StyledForm>
                  ))
                ) : (
                  <div className="no_result">No Agents details found.</div>
                )}
              </div>
            ) : (
              <div className="docs-listing">
                {data?.length > 0 ? (
                  data.map((item, index) => (
                    <StyledForm style={{ paddingBottom: "20px" }}>
                      <BodyContainer>
                        <CheckboxContainer style={{ marginTop: 6 }}>
                          <CustomCheckbox
                            onChange={(event) =>
                              setData((prevData) => {
                                // Use map to create a new array with the updated object
                                return prevData.map((obj) => {
                                  if (obj.id === item?.id) {
                                    // Use spread operator to copy the object and update the key
                                    return {
                                      ...obj,
                                      selected: event.target.checked,
                                    };
                                  }
                                  return obj; // Return the original object if not the one to be updated
                                });
                              })
                            }
                            // onChange={(ev) => handleSelect(ev, item?.id)}
                            checked={item?.selected}
                            name={item?.name + index}
                            key={item.name}
                          />

                          <CheckBoxLabel for="secretDoc">
                            {item.name}
                          </CheckBoxLabel>
                        </CheckboxContainer>
                      </BodyContainer>
                    </StyledForm>
                  ))
                ) : (
                  <div className="no_result">No Documents found.</div>
                )}
              </div>
            )}
            <ButtonContainer>
              <PrimaryOutlinedCTAButton
                style={{ width: "45%", padding: "10px 10px" }}
                onClick={() => (state ? setState(false) : setModal(false))}
              >
                {state ? "Back" : "Cancel"}
              </PrimaryOutlinedCTAButton>
              <PrimaryCTAButton
                style={{ width: "45%", padding: "10px 10px" }}
                disabled={
                  state
                    ? !userDetails.filter((selected) => selected?.selected)
                        .length
                    : !selectedItem?.length
                }
                onClick={() =>
                  state ? onFormSubmit(id, selectedItem) : setState(true)
                }
              >
                {state ? "Send" : "Next"}
              </PrimaryCTAButton>
            </ButtonContainer>
          </FormContainer>
        </Container>
      </DrawerContainer>
    </Modal>
  );
};

export default WhatsAppDoc;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 15px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CustomCheckbox = ({ ...props }) => (
  <Checkbox
    color={"primary"}
    icon={
      <img
        src={require("../../../../assets/images/checkbox.svg")}
        alt="check"
      />
    }
    checkedIcon={
      <img
        src={require("../../../../assets/images/checkBoxSelected.svg")}
        alt="check"
      />
    }
    {...props}
  />
);

const CheckBoxLabel = styled.label`
  font-size: 16px;
  width: 60%;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
`;

const FormContainer = styled.p`
  padding: 24px 15px 30px 15px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  & .docs-listing {
    backface-visibility: hidden;
    height: 340px;
    overflow: auto;
    z-index: -1px;
    overflow-x: hidden;
  }
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 24px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
  }
`;

const DrawerContainer = styled.div`
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & .no_result {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${COLORS.COLOR_DARK};
    font-family: ${theme.fonts.primaryFontSemiBold};
  }
  & .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  & .add_circle {
    margin: 0px 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 131, 140, 0.13);
    cursor: pointer;
    & .add_icon {
      font-size: 14px;
      color: ${COLORS.BTN_GREEN};
    }
  }
  & .users_name {
    font-size: 16px;
    font-weight: 600;
    flex: 1;
    color: ${COLORS.COLOR_DARK};
    font-family: ${theme.fonts.primaryFontSemiBold};
  }
  & .users_color {
    color: #006400;
  }
  & .document_name {
    font-size: 14px;
    font-weight: 600;
    flex: 1;
    color: #8294a5;
    font-family: ${theme.fonts.primaryFontSemiBold};
  }
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 50%;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-around;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 40px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

export const Label = styled.div`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 10px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.7;
  margin-bottom: 6px;
  color: ${COLORS.COLOR_DARK};
  text-align: left;
  & span {
    padding: 0px;
  }
`;

const BodyContainer = styled.div`
  width: 98%;
  border-radius: 10px;
  background-color: white;
  border: solid 0.5px #e1e3ee;
  padding: 4px 5px 14px 5px;
`;

const StyledForm = styled.div``;
