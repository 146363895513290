import React from "react";
import Drawer from "@material-ui/core/Drawer";

const Index = ({ modal, setModal, children }) => {
  return (
    <Drawer
      anchor={"right"}
      open={modal.state}
      onClose={() => setModal(false)}
      disableRestoreFocus={true}
    >
      <div style={{ width: 618 }} role="presentation">
        {children}
      </div>
    </Drawer>
  );
};

export default Index;
