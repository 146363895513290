import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal } from "@material-ui/core";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { CustomCheckbox } from "../../common/FormInputs";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../common/Buttons";
import { caseDocumentType } from "../../../helpers/constants";
import CustomSelect from "../../common/CustomSelect/CustomSelect";
import CaseService from "../../../services/CaseService";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";

export default function DocumentUploadModal({
  open,
  setOpen,
  MTRef,
  setReEdit,
}) {
  const [checked, setChecked] = useState();
  const [checkedme, setCheckedme] = useState("");
  const [type, setType] = useState("");
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (open?.state) {
      setChecked("");
      setCheckedme("");
      setType("");
    } else {
      setChecked("");
      setCheckedme("");
      setType("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open?.state]);

  async function onFormSubmit(id, type, checked, checkedme) {
    try {
      setLoader({ state: true, message: `Publish Dictation...` });
      let visibilityType;
      if (!checked && !checkedme) visibilityType = "me";
      else if (checked && !checkedme) visibilityType = "others";
      else if (!checked && checkedme) visibilityType = "all";
      const payload = {
        documentType: type,
        visibilityType: visibilityType,
      };
      const response = await CaseService.publishDictationContent(payload, id);
      if (response) {
        enqueueSnackbar(response?.message, { variant: "success" });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      MTRef.current.onQueryChange("");
      setReEdit("");
      setOpen({ state: false });
    }
  }

  return (
    <Modal
      open={open?.state}
      onClose={() => setOpen({ state: false })}
      disableRestoreFocus={true}
    >
      <DrawerContainer role="presentation">
        <Container className="docs-listing">
          <HeadingContainer>
            <Heading>Publish Document</Heading>
            <CloseModal
              onClick={() => setOpen({ state: false })}
              src={require("../../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          <FormContainer>
            {open?.state && (
              <div style={{ marginBottom: 10, width: "90%" }}>
                <Label>Document Type</Label>
                <CustomSelect
                  white={true}
                  id={"uploadtype"}
                  menuItemValues={caseDocumentType}
                  name={"uploadtype"}
                  value={type}
                  onChange={(e) => setType(e?.target?.value)}
                  style={{ marginTop: 6 }}
                />
              </div>
            )}
            <CheckboxContainer style={{ marginTop: 6 }}>
              <CustomCheckbox
                checked={checked}
                onChange={(e) =>
                  setChecked(e?.target?.checked) || setCheckedme(false)
                }
              />
              <CheckBoxLabel for="secretDoc">{"Share with CM"}</CheckBoxLabel>
            </CheckboxContainer>
            <CheckboxContainer style={{ marginTop: 6 }}>
              <CustomCheckbox
                checked={checkedme}
                onChange={(e) =>
                  setCheckedme(e?.target?.checked) || setChecked(false)
                }
              />
              <CheckBoxLabel for="secretDoc">
                {"Share with all parties and CM"}
              </CheckBoxLabel>
            </CheckboxContainer>
            <ButtonContainer>
              <PrimaryOutlinedCTAButton
                style={{ width: "45%" }}
                onClick={(e) => {
                  e.preventDefault();
                  setOpen({ state: false });
                }}
              >
                Cancel
              </PrimaryOutlinedCTAButton>
              <PrimaryCTAButton
                style={{ width: "45%" }}
                onClick={(e) => {
                  e.preventDefault();
                  onFormSubmit(open?.id, type, checked, checkedme);
                }}
              >
                Publish
              </PrimaryCTAButton>
            </ButtonContainer>
          </FormContainer>
        </Container>
      </DrawerContainer>
    </Modal>
  );
}

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & .docs-listing {
    overflow: auto;
    height: 375px;
    border: 4px solid transparent;
    border-radius: 8px;
  }
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 446px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const FormContainer = styled.p`
  padding: 24px 15px 30px 15px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  min-height: 200px;
`;

const Label = styled.div`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 10px;
  line-height: 1.7;
  text-transform: uppercase;
  color: ${COLORS.COLOR_DARK};
`;

const CheckBoxLabel = styled.label`
  font-size: 16px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-around;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;
