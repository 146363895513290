import React from "react";
import TranscriptEditor from "./TranscriptEditor";

const Index = ({
  caseId,
  mediaUrl,
  data,
  setData,
  meetingId,
  transcriptId,
  title,
  refreshDetails,
  render,
  setRender,
  captureText,
  name,
  role,
}) => {
  return (
    <React.Fragment>
      <div style={{ paddingTop: "2px" }}>
        <TranscriptEditor
          class="playerButton"
          width="100%"
          mediaUrl={mediaUrl}
          isEditable={false}
          spellCheck={false}
          mediaType={"audio"}
          {...{
            data,
            setData,
            meetingId,
            caseId,
            transcriptId,
            title,
            refreshDetails,
            render,
            setRender,
            captureText,
            name,
            role,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default Index;
