import { Modal } from "@material-ui/core";
import React, { useState } from "react";
import { Cropper } from "react-cropper";
import { Heading, HeadingContainer } from "./style";
import { PrimaryCTAButton } from "../Buttons";
import { CloseModal, DrawerContainer } from "./style";

const ImageCropper = ({
  openCropperModal,
  setOpenCropperModal,
  preCropImage,
  onCropped,
  setPreCropImage,
}) => {
  const [cropper, setCropper] = useState();
  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      onCropped(cropper.getCroppedCanvas().toDataURL());
    }
  };
  return (
    <Modal open={openCropperModal}>
      <DrawerContainer role="presentation">
        <HeadingContainer style={{ padding: "30px 0px" }}>
          <Heading>Crop Image</Heading>
          <CloseModal
            onClick={() => {
              setPreCropImage("");
              setOpenCropperModal(false);
            }}
            src={require("../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <div>
          <Cropper
            style={{ height: 500 }}
            initialAspectRatio={1}
            preview=".img-preview"
            src={preCropImage}
            viewMode={1}
            guides={true}
            dragMode={"move"}
            scalable={false}
            cropBoxResizable={false}
            minCropBoxHeight={150}
            minCropBoxWidth={150}
            frameBorder={9999}
            background={false}
            responsive={true}
            autoCropArea={0.5}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />
        </div>
        <div
          className="p15"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PrimaryCTAButton
            style={{ maxWidth: 200 }}
            type="button"
            onClick={getCropData}
          >
            Crop Image
          </PrimaryCTAButton>
        </div>
      </DrawerContainer>
    </Modal>
  );
};

export default ImageCropper;
