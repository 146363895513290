import React, { useEffect } from "react";
import { Center } from "../../../styles/component/style";
import { AccountVerification } from "../../internal/AccountVerification/AccountVerification";
import { Header } from "../../internal/Header/Header";
import AuthService from "../../../services/AuthService";
import { useSnackbar } from "notistack";
import useLoader from "../../../hooks/useLoader";
import { AccVerifyContainer } from "../AccoutVerificationPage/Styles";
import { navigate } from "@reach/router";
import { getErrorMessage } from "../../../helpers/functions";

const Index = ({ location }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const { state } = location;
  async function resendEmail() {
    try {
      setLoader({ state: true, message: "Sending verfication email..." });
      const payload = { email: state.email };
      const response = await AuthService.forgotPassword(payload);
      if (response) {
        enqueueSnackbar(
          "Verification mail sent successfully. Please check your Junk Folder/ Promotions Folder incase you don't receive the email",
          {
            variant: "success",
          }
        );
      }
    } catch (error) {
      console.log(error);
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
      navigate("/");
    } finally {
      setLoader({ state: false });
    }
  }
  useEffect(() => {
    if (state?.email) {
      resendEmail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.email]);
  if (!state?.email) navigate("/");
  return (
    <Header>
      <Center>
        <AccVerifyContainer>
          <AccountVerification
            heading="FORGOT PASSWORD"
            timer={60}
            isTimmerEnabel={true}
            message={`We’ve sent a reset password link to your registered email. Please check your inbox and update your password to access your account`}
            triggerResent={resendEmail}
            location={location}
          />
        </AccVerifyContainer>
      </Center>
    </Header>
  );
};

export default Index;
