import AwesomeDebouncePromise from "awesome-debounce-promise";
import { useState } from "react";
import useConstant from "use-constant";
import { useAsync } from "react-async-hook";

const useDebouncedSearch = (
  searchFunction,
  timmer = 1500,
  initialState = undefined
) => {
  // Handle the input text state
  const [inputText, setInputText] = useState(initialState);
  // Debounce the original search async function
  const debouncedSearchFunction = useConstant(() =>
    AwesomeDebouncePromise(searchFunction, timmer)
  );

  // The async callback is run each time the text changes,
  // but as the search function is debounced, it does not
  // fire a new request on each keystroke
  const searchResults = useAsync(async () => {
    if (typeof inputText === "string")
      return debouncedSearchFunction(inputText);
  }, [debouncedSearchFunction, inputText]);

  // Return everything needed for the hook consumer
  return {
    inputText,
    setInputText,
    searchResults,
  };
};

export default useDebouncedSearch;
