import React from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
const Index = ({ text = "title", status = "success", size = "10px" }) => {
  return (
    <Badge status={status} size={size}>
      {text}
    </Badge>
  );
};

const Badge = styled.div`
  padding: 8px 16px;
  border-radius: 5px;
  text-transform: capitalize;
  font-size: ${(props) => props.size};
  font-family: ${theme.fonts.primaryFontSemiBold};
  background: ${(props) =>
    props.status === "success"
      ? "rgba(0, 131, 140, 0.2)"
      : props.status === "warning"
      ? "#ffebd9"
      : "rgba(255, 60, 60, 0.2)"};
  color: ${(props) =>
    props.status === "success"
      ? "#00838c"
      : props.status === "warning"
      ? "#dd5d3c"
      : "#ff3c3c"};
`;

export default Index;
