import { useSnackbar } from "notistack";
import React from "react";
import { GoogleLogin } from "react-google-login";
import styled from "styled-components";
import theme from "../../../assets/theme";

const GLogin = ({ onSuccess, className = "", label = "login" }) => {
  const { enqueueSnackbar } = useSnackbar();

  const onFailure = ({ error }) => {
    if (error === "access_denied" || error === "immediate_failed") {
      enqueueSnackbar("Could not login", {
        variant: "error",
      });
    }
  };

  return (
    <GLoginContainer>
      <GoogleLogin
        className={`${className} w100p`}
        clientId={process.env.REACT_APP_GLOGIN_CLIENT_ID}
        buttonText={label}
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
      />
    </GLoginContainer>
  );
};

export default GLogin;

const GLoginContainer = styled.div`
  & button {
    border: solid 1px #e6e6e6 !important;
    box-shadow: none !important;
    outline: none;
    & span {
      width: 80%;
      padding: 0px;
      color: #333333;
      font-family: ${theme.fonts.primaryFontSemiBold};
    }
  }
`;
