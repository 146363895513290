import API from "../api/manager";
import config from "../api/config";

const verifications = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.verifications,
      data,
    },
    false
  );
};

const validateMobile = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.validate_mobile,
      data,
    },
    false
  );
};
const validateEmail = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.validate_email,
      data,
    },
    false
  );
};

const profileUpdate = (data) => {
  return API({
    method: "PUT",
    url: config.urls.auth.profile,
    data,
  });
};
const profile = () => {
  return API({
    method: "GET",
    url: config.urls.auth.profile,
  });
};
const login = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.login,
      data,
    },
    false
  );
};

const loginWithgoogle = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.loginWithGoole,
      data,
    },
    false
  );
};

const otpLogin = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.otp_login,
      data,
    },
    false
  );
};

const resendEmail = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.email,
      data,
    },
    false
  );
};

const forgotPassword = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.forgot_password,
      data,
    },
    false
  );
};

const resetPassword = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.reset_password,
      data,
    },
    false
  );
};

const changePassword = (data) => {
  return API({
    method: "PUT",
    url: config.urls.auth.change_password,
    data,
  });
};

export default {
  verifications,
  validateMobile,
  validateEmail,
  profileUpdate,
  profile,
  login,
  otpLogin,
  resendEmail,
  forgotPassword,
  resetPassword,
  changePassword,
  loginWithgoogle,
};
