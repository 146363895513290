import React, { useState } from "react";

export const DictationContext = React.createContext();

function DictationProvider({ children }) {
  const [comment, setComment] = useState();
  const [reset, setReset] = useState(true);

  const value = React.useMemo(() => {
    return {
      comment,
      setComment,
      reset,
      setReset,
    };
  }, [comment, setComment, reset, setReset]);

  return (
    <DictationContext.Provider value={value}>
      {children}
    </DictationContext.Provider>
  );
}

export default DictationProvider;
