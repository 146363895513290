import React, { Fragment, useState, useEffect, useRef } from "react";
import ActionBar from "../../common/ActionBar";
import { PageWrapper, StyledLabel } from "./styles";
import LifeCycle from "../../common/LifeCycle/LifeCycle";
import CaseDetailsCard from "../../common/CaseDetails";
import CaseManager from "./common/CaseManager";
import BigBlueButton from "../CaseDetails/common/BigBlueButton";
import _ from "lodash";
import { navigate } from "@reach/router";
import CaseService from "../../../services/CaseService";
import useLoader from "../../../hooks/useLoader";
import {
  RESPONDANT_ONBOARDED,
  CLAIMENT_STR,
  NEGOTIATION_REACHED,
  NEGOTIATION_QUIT_BY_CLAIMENT,
  NEGOTIATION_QUIT_BY_RESPONDENT,
  CLAIMENT_PAID_NEGOTIATION,
  RESPONDENT_PAID_NEGOTIATION,
  BOTH_PAID_NEGOTIATION,
  NEGOTIATION_QUIT_BY_CASEMANAGER,
  AWAITING_RESPONDANT_QUIT,
  LIFE_CYCLE,
} from "../../../helpers/constants";
import { getErrorMessage } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import MediationState from "./MediationState";
import DrawerTable from "../../internal/DrawerTable";
import queryString from "query-string";
import useNotification from "../../../hooks/useNotification";
import { CaseManagerCardData } from "./styles";
import { PrimaryCTAButton } from "../../common/Buttons";
import ExtraFieldsDrawer from "../../internal/DrawerTable/ExtraFieldsDrawer";

export default function CaseDetails({ id }) {
  const initialState = ["My Cases"];
  const [state, setState] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState(initialState); // This state is to display the bread crum
  const { setLoader } = useLoader(); // This Hook is to display the loader
  const [caseDetails, setCaseDetails] = useState({}); // This state holds the case detaisl
  const [caesLifeCycle, setCaseLifeCyle] = useState([]); // This state holds the case lifecycle
  const [isRespondent, setIsRespondent] = useState(false); // This state tells current logged in user is respondent or not
  const [ownerId, setOwnerId] = useState(); // this state holds the ownerId
  const [respondentId, setRespondentId] = useState(); // THis state holds the respondent Id
  const { enqueueSnackbar } = useSnackbar(); // This Hook is used to trigger the snackbar message
  const [currentState, setCurrentState] = useState({}); // This state Holds the current state
  const MTRef = useRef();
  const [pendingRegistrationFee, setPendingRegistrationFee] = useState(false);
  const [comments, setComments] = useState([]); // This is list of comments
  const [partyList, setPartyList] = useState([]);
  const { triggerNotify, setTriggerNotify } = useNotification(); // hooks to refresh the page
  const [triggerCaseCall, setTriggerCaseCall] = useState(true); // This State to trigger the get details call when any update happend in the case
  const [statusCheck, setStatusCheck] = useState();
  const [type, setType] = useState();
  const [docUploadModal, setDocUploadModal] = useState();
  const [fields, setFields] = useState(false);

  useEffect(() => {
    if (triggerNotify) {
      setTriggerCaseCall(true);
      setTriggerNotify(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerNotify]);

  useEffect(() => {
    async function enableLiveTranscription(id, resolutionKind) {
      try {
        if (resolutionKind !== "negotiation") {
          const transcriptionControl =
            await CaseService.enableLiveTranscription(id);
          setStatusCheck(transcriptionControl);
        } else {
        }
        // return;
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    }
    if (id && caseDetails?.resolutionKind) {
      enableLiveTranscription(id, caseDetails?.resolutionKind);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, caseDetails?.resolutionKind]);

  /**THis useEffect is Used to get the case information */
  useEffect(() => {
    /** Function to get the CaseDetails */
    async function getCaseById(id) {
      setLoader({ state: true, message: "Fetching case details..." });
      try {
        const res = await CaseService.getCase(id);
        if (res) {
          let case_response = { ...res };
          if (case_response.status !== "draft") {
            if (case_response.status === "pendingRegistrationFee") {
              setPendingRegistrationFee(true);
            }
            if (!case_response.respondentPartyId) {
              case_response.status = res?.status;
            } else if (
              case_response.respondentStatus === "pending" ||
              case_response.respondentStatus === "declined"
            ) {
              case_response.status = RESPONDANT_ONBOARDED;
            } else if (
              case_response?.status === NEGOTIATION_REACHED &&
              case_response?.subscriptionKind === "adhoc"
            ) {
              case_response.status = BOTH_PAID_NEGOTIATION;
            }
          }
          getCaseMediation(id);
          setCaseDetails(case_response);
          setBreadcrumbs(["My Cases", case_response.title]);
          setCaseLifeCyle(JSON.parse(JSON.stringify(LIFE_CYCLE)));
          setIsRespondent(case_response.agentRole !== CLAIMENT_STR);
          setOwnerId(case_response.claimantPartyId);
          setRespondentId(case_response.respondentPartyId);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
        navigate("/mediator/cases");
      } finally {
        setLoader({ state: false });
        window.scrollTo(0, 0);
      }
    }

    /** Function to get the negotiation round Details */
    async function getCaseMediation(id) {
      setLoader({ state: true, message: "Fetching case details..." });
      try {
        let params = {
          page: 1,
          perPage: 1000,
        };
        let stringParams = "";
        if (!_.isEmpty(params)) {
          stringParams = `?${queryString.stringify(params)}`;
        }
        const mediation_res = await CaseService.mediations(id, stringParams);
        if (mediation_res) {
          setComments(mediation_res?.data);
        }

        const partyParam = `?${queryString.stringify(
          params,
        )}&sort=partyRole&sortDirection=asc`;

        const party_list_res = await CaseService.getPartyListByCaseId(
          id,
          partyParam,
        ); // This is for getting the party details based on the case
        if (party_list_res?.data) {
          setPartyList(party_list_res?.data);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
    if (id && triggerCaseCall) {
      getCaseById(id);
      setTriggerCaseCall(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerCaseCall]);

  useEffect(() => {
    (async function () {
      try {
        const response = await CaseService.getTranscriptionType(id);
        if (response) {
          setType(response);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    })(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** THis is useEffect is used to set the curretState based on the status **/
  useEffect(() => {
    if (caesLifeCycle.length && !_.isEmpty(caseDetails)) {
      if (caesLifeCycle.some((el) => el.key === caseDetails.status)) {
        setCurrentState(
          caesLifeCycle.find((el) => el.key === caseDetails.status),
        );
      } else if (!isRespondent) {
        if (caseDetails.respondentId) {
          setCurrentState(caesLifeCycle.find((el) => el.index === 2));
        } else {
          setCurrentState(caesLifeCycle.find((el) => el.index === 1));
        }
      } else if (caseDetails.status === "finalAward") {
        setCurrentState({
          label: "Final Award",
          key: "finalAward",
          index: 5,
          enableSelection: false,
          cta: [],
        });
      }
    }
  }, [caesLifeCycle, caseDetails, isRespondent]);

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "my_cases":
        navigate(`/mediator/cases`);
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      <ActionBar
        breadcrumbs={breadcrumbs}
        onBreadcrumbClick={onBreadcrumbClick}
      />
      <PageWrapper>
        <div>
          <StyledLabel>Case Lifecycle</StyledLabel>
          <LifeCycle
            items={caesLifeCycle}
            selectedIndex={currentState?.index}
            isMediation={true}
            resolutionKind={caseDetails?.resolutionKind}
            status={currentState.text}
            currentStateKey={currentState?.key}
          />
        </div>
        <div style={{ marginTop: 28 }}>
          <CaseDetailsCard
            isActionsDisabled={
              currentState?.key === NEGOTIATION_REACHED ||
              currentState?.key === NEGOTIATION_QUIT_BY_CLAIMENT ||
              currentState?.key === NEGOTIATION_QUIT_BY_RESPONDENT ||
              currentState?.key === NEGOTIATION_QUIT_BY_CASEMANAGER ||
              currentState?.key === CLAIMENT_PAID_NEGOTIATION ||
              currentState?.key === RESPONDENT_PAID_NEGOTIATION ||
              currentState?.key === BOTH_PAID_NEGOTIATION ||
              currentState?.key === AWAITING_RESPONDANT_QUIT ||
              currentState?.key === "finalAward"
            }
            isViewAction={
              currentState?.key === NEGOTIATION_QUIT_BY_CLAIMENT ||
              currentState?.key === NEGOTIATION_QUIT_BY_RESPONDENT ||
              currentState?.key === NEGOTIATION_QUIT_BY_CASEMANAGER
            }
            {...{ setState }}
            caseDetails={caseDetails}
            pendingRegistrationFee={pendingRegistrationFee}
            {...{
              setState,
              id,
              MTRef,
              partyList,
              setTriggerCaseCall,
              docUploadModal,
              setDocUploadModal,
              setFields,
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="flex wrap">
            {caseDetails?.caseManager ? (
              <div className="mr25 mt28">
                <CaseManager
                  disabled={caseDetails?.caseManager?.disabled}
                  caseManager={caseDetails?.caseManager}
                />
              </div>
            ) : null}
            {caseDetails?.requestedMediators?.length ? (
              <>
                {caseDetails?.requestedMediators
                  ?.filter((el) => el?.id)
                  ?.map((mediator, index) => (
                    <div className="mr25 mt25">
                      <CaseManager
                        key={index}
                        caseManager={mediator}
                        title={
                          caseDetails?.resolutionKind === "mediation"
                            ? "Mediator"
                            : "Arbitrator"
                        }
                      />
                    </div>
                  ))}
              </>
            ) : null}
            <div className="mr25 mt28">
              <BigBlueButton
                id={caseDetails?.id}
                caseDetails={caseDetails}
                isActionsDisabled={
                  currentState?.key === NEGOTIATION_REACHED ||
                  currentState?.key === NEGOTIATION_QUIT_BY_CLAIMENT ||
                  currentState?.key === NEGOTIATION_QUIT_BY_RESPONDENT ||
                  currentState?.key === BOTH_PAID_NEGOTIATION ||
                  currentState?.key === "finalAward"
                }
                status={caseDetails?.status}
                resolutionKind={caseDetails?.resolutionKind}
                type={`Join ${_.capitalize(
                  caseDetails?.resolutionKind,
                )} Session`}
              />
            </div>
            {statusCheck?.enableTranscription === "yes" ? (
              <div className="mr25 mt28">
                {caseDetails?.resolutionKind !== "negotiation" && (
                  <CaseManagerCardData>
                    <PrimaryCTAButton
                      style={{ width: "258px" }}
                      onClick={() =>
                        navigate(
                          type?.crossExamination
                            ? `/mediator/cases/${id}/cross-examination?caseType=${caseDetails?.resolutionKind}`
                            : `/mediator/cases/${id}/livetranscription?caseType=${caseDetails?.resolutionKind}`,
                          {
                            state: type?.crossExamination ? type : caseDetails,
                          },
                        )
                      }
                    >
                      {type.crossExamination
                        ? "Cross Examination"
                        : "Live Transcription"}
                    </PrimaryCTAButton>
                  </CaseManagerCardData>
                )}
              </div>
            ) : (
              ""
            )}
            {/* {statusCheck?.enableTranscription === "yes" ? (
              <div className="mr25 mt28">
                {caseDetails?.resolutionKind !== "negotiation" && (
                  <CaseManagerCardData>
                    <PrimaryCTAButton
                      style={{ width: "258px" }}
                      onClick={() =>
                        navigate(
                          `/mediator/cases/${id}/cross-examination?caseType=${caseDetails?.resolutionKind}`,
                          { state: caseDetails }
                        )
                      }
                    >
                      {"Cross Examination"}
                    </PrimaryCTAButton>
                  </CaseManagerCardData>
                )}
              </div>
            ) : (
              ""
            )} */}
          </div>
        </div>
        <div style={{ marginTop: 28 }}>
          <MediationState
            {...{
              currentState,
              isRespondent,
              ownerId,
              respondentId,
              caseDetails,
              comments,
              setComments,
              partyList,
            }}
            isLocalTranscriptionServer={type?.isLocalTranscriptionServer}
          />
        </div>
      </PageWrapper>
      <DrawerTable
        isActionsDisabled={
          currentState?.key === NEGOTIATION_REACHED ||
          currentState?.key === NEGOTIATION_QUIT_BY_CLAIMENT ||
          currentState?.key === NEGOTIATION_QUIT_BY_RESPONDENT ||
          currentState?.key === NEGOTIATION_QUIT_BY_CASEMANAGER ||
          currentState?.key === CLAIMENT_PAID_NEGOTIATION ||
          currentState?.key === RESPONDENT_PAID_NEGOTIATION ||
          currentState?.key === BOTH_PAID_NEGOTIATION ||
          currentState?.key === AWAITING_RESPONDANT_QUIT ||
          currentState?.key === "finalAward"
        }
        title={caseDetails?.title}
        {...{ state, setState, id, MTRef, caseDetails }}
        type={caseDetails?.resolutionKind}
        claimantId={caseDetails?.claimantParty?.ownerId}
        respondentId={caseDetails?.respondentParty?.ownerId}
        agreementsAndAwards={caseDetails?.agreementsAndAwards}
      />
      <ExtraFieldsDrawer {...{ fields, setFields, caseDetails, id }} />
    </Fragment>
  );
}
