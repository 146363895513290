import API from "../api/manager";
import config from "../api/config";
import Axios from "axios";

const getS3URL = (data) => {
  return API({
    method: "POST",
    url: config.urls.s3,
    data,
  });
};

const uploadToS3 = (url, file) => {
  return new Promise((resolve, reject) => {
    Axios.put(url, file, {
      headers: {
        "Content-Type": file?.type,
      },
    })
      .then((_) => {
        resolve(url?.split("?")[0]);
      })
      .catch((err) => {
        console.log("err", err);
        reject(err);
      });
  });
};

export default { getS3URL, uploadToS3 };
