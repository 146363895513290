import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";

export const Container = styled.div`
  padding: 34px 43px;
  display: flex;
  flex-direction: column;
`;

export const TableWrapper = styled.div`
  
`
export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  cursor: pointer;
`
export const StyledTitle = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  width:200px;
`
export const ButtonWrapper = styled.div`
  display:flex;
  align-items:center;
  justify-content:flex-start;
`
export const AcceptButton = styled.button`
  border:none;
  background:none;
  outline:none;
  border-radius:50%;
  width: 20px;
  height: 20px;
  margin-left: 4px;
  margin-right:20px;
`
export const DeclineButton = styled.button`
  border:none;
  background:none;
  outline:none;
  border-radius:50%;
  width: 20px;
  height: 20px;
`