import styled from "styled-components";
import theme from "../../../assets/theme";

export const OtpMessageContainer = styled.div`
  padding: 40px 0px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  & .msg {
    width: 390px;
    @media (max-width: 600px) {
      width: 80vw;
      font-color: #293461;
      font-family: ${theme.fonts.primaryFontRegular};
    }
  }
  & .inputs {
    padding-top: 25px;
    .react-code-input {
      input {
        box-shadow: none !important;
        outline: none;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        background-color: #f9f9f9 !important;
        border-radius: 4px !important;
        font-family: ${theme.fonts.primaryFontBold} !important;
        font-size: 24px !important;
        padding-left: 10px !important;
        @media (max-width: 600px) {
          width: 15vw !important;
          height: 15vw !important;
          padding-top: 21px !important;
          padding-bottom: 21px !important;
          padding-left: 21px !important;
        }
      }
    }
  }
`;
