import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Drawer } from "@material-ui/core";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { getErrorMessage, parseTimeStamp } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import useLoader from "../../../hooks/useLoader";
import CaseService from "../../../services/CaseService";
import moment from "moment";
import MoreOrLess from "../../common/MoreOrLess";
import { CustomInputField } from "../../common/FormInputs";
import { PrimaryCTAButton } from "../../common/Buttons";
import _ from "lodash";
import queryString from "query-string";
import { sanitize } from "dompurify";

export default function ViewNotesModal({
  modal,
  setModal,
  id,
  type = "Mediator",
  disabled = false,
}) {
  const [notes, setNotes] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const [note, setNote] = useState("");
  const ref = useRef();

  const addNotes = async (value) => {
    try {
      setLoader({ state: true, message: "Adding notes..." });
      const payload = {
        content: value,
      };
      const res = await CaseService.addNotes(payload, id);
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
        notes.unshift(res?.newNote);
        setNote("");
        ref.current.scrollTop = 0;
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  useEffect(() => {
    async function getAllNotes(id) {
      try {
        setLoader({ state: true, message: "loading Notes..." });
        let params = {
          page: 1,
          perPage: 1000,
        };
        let stringParams = "";
        if (!_.isEmpty(params)) {
          stringParams = `?${queryString.stringify(params)}`;
        }
        const res = await CaseService.getNotes(id, stringParams);
        if (res?.data?.length) {
          setNotes(res?.data);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
    if (id && modal) {
      getAllNotes(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, modal]);

  return (
    <Drawer
      anchor={"right"}
      open={modal}
      onClose={() => setModal(false)}
      disableRestoreFocus={true}
    >
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>Scratch Pad</Heading>
          <CloseModal
            onClick={() => setModal(false)}
            src={require("../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <NoteContainer ref={ref}>
          {notes?.length > 0 ? (
            <div>
              {notes?.map((note) => (
                <div className="note">
                  <div className="time">
                    {moment(parseTimeStamp(note?.created_at)).format(
                      "DD/MM/yyyy"
                    )}{" "}
                    AT{" "}
                    {moment(parseTimeStamp(note?.created_at)).format("hh:mm a")}
                  </div>
                  <div className="content">
                    <MoreOrLess
                      Paragraph={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: sanitize(note?.content),
                          }}
                        />
                      }
                      className="content"
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                height: "100%",
              }}
            >
              <EmptyMsg>There are no notes added yet.</EmptyMsg>
            </div>
          )}
        </NoteContainer>
        <EditorContainer>
          <div style={{ width: "70%" }}>
            <CustomInputField
              variant="outlined"
              className={"input-height-auto"}
              style={{ marginTop: 6 }}
              multiline={true}
              rows={5}
              placeholder="Type something here…"
              maxRows={5}
              name={"note"}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
          <div style={{ width: "30%" }}>
            <PrimaryCTAButton
              style={{ width: 115 }}
              onClick={() => addNotes(note)}
              disabled={!note?.trim()}
            >
              Add Note
            </PrimaryCTAButton>
          </div>
        </EditorContainer>
      </DrawerContainer>
    </Drawer>
  );
}

const DrawerContainer = styled.div`
  width: 600px;
  height: 100%;
  background-color: white;
  outline: none;
`;

const EditorContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  background-color: ${COLORS.ADD_NOTE_TEXT_AREA};
  bottom: 0px;
  width: 100%;
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & .MuiOutlinedInput-multiline {
    background-color: transparent !important;
    padding: 10px 30px !important;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 20px;
  padding-left: 38px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  position: relative;
  border-bottom: 1px solid ${COLORS.INPUT_BORDER};
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const NoteContainer = styled.div`
  padding: 30px;
  overflow: auto;
  height: calc(100% - 210px);
  & .note {
    margin: 15px 0px;
    & .time {
      font-family: ${theme.fonts.primaryFontSemiBold};
      font-size: 10px;
      font-weight: 600;
      line-height: 1.7;
      color: ${COLORS.INPUT_LABEL};
    }
    & .content {
      font-family: ${theme.fonts.primaryFontSemiBold};
      font-size: 14px;
      font-weight: 600;
      line-height: 1.71;
      color: ${COLORS.COLOR_DARK};
      word-break: break-word;
      & .readMoreText {
        color: ${COLORS?.BTN_GREEN} !important;
      }
    }
  }
`;

const EmptyMsg = styled.div`
  color: ${COLORS.COLOR_DARK};
  font-weight: 500;
  letter-spacing: 0.1px;
  font-size: 24px;
  margin: 20px 0px;
  font-family: ${theme.fonts.primaryFontBold};
  text-align: center;
`;
