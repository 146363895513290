import React from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import Drawer from "../../common/Drawer/AgentListDrawerModal";
import { Heading } from "../../../styles/component/style";
import { makeStyles } from "@material-ui/core";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#f9f9f9",
    boxShadow: "none",
    margin: "35px 0px 0px !important",
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 15,
      backgroundColor: "#fff",
    },
    "& .MuiSvgIcon-root": {
      fill: "#00838c",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Index = ({ fields, setFields, caseDetails, id }) => {
  return (
    <Drawer state={fields} setState={setFields} label="Drawer">
      <Table
        {...{
          fields,
          setFields,
          caseDetails,
          id,
        }}
      />
    </Drawer>
  );
};

export default Index;

const Table = ({ setFields, caseDetails }) => {
  const classes = useStyles();
  return (
    <div>
      <Header>
        <Heading style={{ marginBottom: "unset" }}>Case Details</Heading>
        <ImageWrapper>
          <img
            src={require("../../../assets/images/closeModal.svg")}
            onClick={() => {
              setFields(false);
            }}
            alt="close"
          />
        </ImageWrapper>
      </Header>
      <AccordionContainer>
        <TableContainer className={classes.root}>
          <HeadingContainer>
            {/* <TableHeading>{"Bulk upload extra fields"}</TableHeading> */}
          </HeadingContainer>
          <div className="ml40 mr40">
            <div
              className="mt20 mb20 h300"
              style={{
                overflow: "auto",
              }}
            >
              {caseDetails.bulkUploadExtraFields &&
                Object.keys(caseDetails.bulkUploadExtraFields).map((key) => (
                  <div className="keys_row">
                    <div className="keys_label">{_.startCase(key)}</div>
                    <div className="keys_separator">:</div>
                    <div className="keys_name">
                      {caseDetails.bulkUploadExtraFields[key]}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </TableContainer>
      </AccordionContainer>
    </div>
  );
};

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 19px 40px;
  margin-top: 15px;
  border-bottom: 1px solid ${COLORS.BORDER_GREY};
`;
export const TableContainer = styled.div`
  /* margin: 30px 29px auto 38px; */
  width: 100%;
  & .no-result {
    font-family: ${theme.fonts.primaryFontSemiBold};
    font-size: 16px;
    color: ${COLORS.COLOR_DARK};
    text-align: center;
  }
  & .keys_row {
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    & .keys_name {
      font-size: 16px;
      font-weight: 600;
      flex: 1;
      color: ${COLORS.COLOR_DARK};
      font-family: ${theme.fonts.primaryFontSemiBold};
    }
    & .keys_label {
        font-size: 16px;
        font-weight: 600;
        margin-right: 13px;
        border-radius: 5px;
        background-color: ${COLORS.BORDER_GREY};
        font-family: ${theme.fonts.primaryFontSemiBold};
      }
    & .keys_separator {
        font-size: 16px;
        font-weight: 600;
        margin-right: 13px;
        border-radius: 5px;
        font-family: ${theme.fonts.primaryFontSemiBold};
    }
`;

export const AccordionHeading = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
`;

export const AccordionContainer = styled.div`
  padding: 0 20px;
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
`;
export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledTitle = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  width: 200px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  margin: 0 auto;
  @media (min-width) {
    justify-content: space-between;
  }
`;

export const CheckedIconContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HyperLinkSpan = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-left: 25px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const HeadingContainer = styled.div`
  position: relative;
  text-align: center;
`;

export const TableHeading = styled.span`
  margin-bottom: 31px;
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: 18px;
  flex: 1;
  color: ${COLORS.COLOR_DARK};
  text-transform: capitalize;
`;
