import { navigate, useLocation } from "@reach/router";
import React, { Fragment, useEffect } from "react";

export default function MailRedirection({ id, redirectTo }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  useEffect(() => {
    if (id && redirectTo === "case" && searchParams?.get("caseType")) {
      navigate(`/mediator/cases/${id}`);
    } else if (redirectTo === "help") {
      navigate(`/mediator/help`);
    }
  }, [redirectTo, searchParams, id]);
  return <Fragment />;
}
