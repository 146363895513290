/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components";
import FilterBar from "./FilterBar";
import SearchBar from "./SearchBar";
import useDebouncedSearch from "../../../../hooks/useDebounceSearch";
import theme from "../../../../assets/theme";
import COLORS from "../../../../assets/Colors";
import { HyperLink } from "../../styled-components";
import { CustomCheckbox } from "../../FormInputs";

const TableToolbar = ({
  MTRef,
  selectedFilter,
  setSelectedFilter,
  filters,
  placeholderText,
  hideSearch,
  customFilter,
  filterCount,
  onFilterClick = () => {},
  ...props
}) => {
  const { inputText, setInputText } = useDebouncedSearch(searchInput);

  function searchInput(input) {
    MTRef.current.onSearchChange(input);
  }

  return (
    <>
      <StyledMTableToolbar {...props}>
        <Flex>
          <FilterBar
            filters={filters}
            selectedFilter={selectedFilter}
            MTRef={MTRef}
            setSelectedFilter={setSelectedFilter}
          />
          {customFilter && (
            <CustomFilterButton onClick={() => onFilterClick()}>
              <FilterIcon />
              <FilterText>Filters</FilterText>
              <FilterCount>{filterCount}</FilterCount>
            </CustomFilterButton>
          )}
        </Flex>
        {!hideSearch && (
          <SearchBar
            placeHolderText={placeholderText}
            {...{ inputText, setInputText }}
          />
        )}
      </StyledMTableToolbar>
      {/* {customFilter && !process.env.NODE_ENV === "development" && (
        <SelectedFilters>
          <FilterHeading>Applied Filters</FilterHeading>
          <SpaceBetween>
            <HalfFlexWrap>
              <FilterContainer>
                <FilterName>Counter Party :</FilterName>
                <AppliedFilterChip>yoyo Inc.</AppliedFilterChip>
              </FilterContainer>
              <FilterContainer>
                <FilterName>Party Role :</FilterName>
                <AppliedFilterChip>Claimant</AppliedFilterChip>
              </FilterContainer>
            </HalfFlexWrap>
            <FlexEndWrap>
              <HyperLink style={{ marginRight: 45 }}>
                Remove All filters
              </HyperLink>
              <Block>
                <CustomCheckbox
                  variant="sm"
                  style={{ width: 14, height: 14 }}
                />
                <Label>Only show cases awaiting my actions</Label>
              </Block>
            </FlexEndWrap>
          </SpaceBetween>
        </SelectedFilters>
      )} */}
    </>
  );
};

export default TableToolbar;

const SelectedFilters = styled.div``;

const Block = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 12px;
  color: ${COLORS.COLOR_DARK};
  margin-left: 6px;
`;

const FilterContainer = styled(Block)`
  margin-bottom: 15px;
`;

const HalfFlexWrap = styled(Block)`
  width: 50%;
  flex-wrap: wrap;
`;

const FlexEndWrap = styled(HalfFlexWrap)`
  justify-content: flex-end;
`;

const SpaceBetween = styled(Block)`
  justify-content: space-between;
`;

const FilterName = styled.div`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 12px;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  margin-right: 10px;
`;

const FilterHeading = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 12px;
  letter-spacing: 0.69px;
  color: ${COLORS.COLOR_DARK};
  margin-bottom: 20px;
`;

const AppliedFilterChip = styled.div`
  border-radius: 22px;
  background-color: #00838c;
  height: 26px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  color: ${COLORS.PRIMARY_WHITE};
  display: flex;
  align-items: center;
  padding: 0 8px;
  margin-right: 25px;
`;

const StyledMTableToolbar = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 0px;
  padding-bottom: 17px;
`;

const CustomFilterButton = styled.div`
  display: flex;
  align-items: center;
  width: 116px;
  height: 27px;
  border-radius: 4px;
  border: solid 1px ${COLORS.BTN_GREEN};
  background-color: ${COLORS.PRIMARY_WHITE};
  justify-content: space-between;
  cursor: pointer;
  margin-left: 13px;
  user-select: none;
`;

const FilterIcon = styled.img`
  margin-left: 11px;
`;
const FilterText = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 12px;
`;
const FilterCount = styled.span`
  font-size: 16px;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontSemiBold};
  margin-right: 18px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;
