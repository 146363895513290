import React, { useState, useEffect, memo } from "react";
import styled from "styled-components";
import { Checkbox, Modal } from "@material-ui/core";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { useSnackbar } from "notistack";
import useLoader from "../../../hooks/useLoader";
import {
  PrimaryOutlinedCTAButton,
  PrimaryCTAButton,
} from "../../common/Buttons";
import { getErrorMessage } from "../../../helpers/functions";
import { CustomInputField } from "../../common/FormInputs";
import CaseService from "../../../services/CaseService";

const Index = ({
  id,
  addpage,
  setAddpage,
  title,
  docName,
  setRefreshDetails,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const [selectedArray, setSelectedArray] = useState(null);

  useEffect(() => {
    let initialArray = [];
    if (docName) {
      docName.map((item) => {
        let newObj = { ...item };
        newObj.checked = false;
        initialArray.push(newObj);
        return initialArray;
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docName]);

  const updateBundleDoc = async () => {
    try {
      setLoader({ state: true, message: "Add section..." });
      const payload = {
        bundleName: title,
        bundleDocuments: [{ ...selectedArray }],
      };
      const res = await CaseService.addSectionOrPagesToBundle(id, payload);
      if (res) {
        enqueueSnackbar("Successfully added section to Bundle", {
          variant: "success",
        });
        setRefreshDetails(true);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setAddpage(false);
      setSelectedArray(null);
    }
  };

  const handleSelect = (item, event) => {
    if (event.target.checked) {
      let newObj = { ...item };
      newObj.checked = true;
      setSelectedArray(newObj);
    }
  };

  return (
    <Modal
      open={addpage}
      onClose={() => setAddpage(false) || setSelectedArray(null)}
      disableRestoreFocus={true}
    >
      <DrawerContainer role="presentation">
        <Container>
          <HeadingContainer>
            <Heading>Add Section</Heading>
            <CloseModal
              onClick={() => setAddpage(false)}
              src={require("../../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>

          <AgentFormContainer {...{ docName }}>
            <div className="docs-listing ">
              {docName
                ? docName.map((item, index) => (
                    <StyledForm style={{ paddingBottom: "20px" }}>
                      <BodyContainer>
                        <CheckboxContainer style={{ marginTop: 6 }}>
                          <CustomCheckbox
                            onChange={(ev) => handleSelect(item, ev)}
                            checked={
                              selectedArray && selectedArray.id === item.id
                            }
                            name={item?.name + index}
                            key={item.name}
                          />
                          <CheckBoxLabel for="secretDoc">
                            {item.name}
                          </CheckBoxLabel>
                          <div className="bundle-doc">
                            <Label>{"page No"}</Label>
                            <CustomInputField
                              className={"input-white"}
                              variant="outlined"
                              value={
                                selectedArray && selectedArray.id === item.id
                                  ? selectedArray.pageNo
                                  : item?.pageNo
                              }
                              disabled={
                                selectedArray && selectedArray.id === item.id
                                  ? false
                                  : true
                              }
                              onChange={(event) =>
                                setSelectedArray({
                                  ...selectedArray,
                                  pageNo: event.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="bundle-doc">
                            <Label>{"position"}</Label>
                            <CustomInputField
                              className={"input-white"}
                              variant="outlined"
                              type="number"
                              disabled={
                                selectedArray && selectedArray.id === item.id
                                  ? false
                                  : true
                              }
                              value={
                                selectedArray && selectedArray.id === item.id
                                  ? selectedArray.position
                                  : ""
                              }
                              onChange={(event) =>
                                setSelectedArray({
                                  ...selectedArray,
                                  position: event.target.value,
                                })
                              }
                            />
                          </div>
                        </CheckboxContainer>
                      </BodyContainer>
                    </StyledForm>
                  ))
                : ""}
            </div>
            <ButtonContainer>
              <PrimaryOutlinedCTAButton
                style={{ width: "45%" }}
                onClick={() => setAddpage(false) || setSelectedArray(null)}
              >
                Cancel
              </PrimaryOutlinedCTAButton>
              <PrimaryCTAButton
                style={{ width: "45%" }}
                disabled={selectedArray === null}
                onClick={() => updateBundleDoc()}
              >
                Submit
              </PrimaryCTAButton>
            </ButtonContainer>
          </AgentFormContainer>
        </Container>
      </DrawerContainer>
    </Modal>
  );
};

export default memo(Index);

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CustomCheckbox = ({ ...props }) => (
  <Checkbox
    color={"primary"}
    icon={
      <img src={require("../../../assets/images/checkbox.svg")} alt="check" />
    }
    checkedIcon={
      <img
        src={require("../../../assets/images/checkBoxSelected.svg")}
        alt="check"
      />
    }
    {...props}
  />
);

const CheckBoxLabel = styled.label`
  font-size: 16px;
  width: 350px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
`;

const AgentFormContainer = styled.p`
  padding: 24px 15px 30px 15px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  & .docs-listing {
    backface-visibility: hidden;
    height: ${({ docName }) => (docName?.length > 3 ? "360px" : "auto")};
    overflow: auto;
    z-index: -1px;
    overflow-x: hidden;
  }
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 24px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
  }
`;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 50%;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-around;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 40px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

export const Label = styled.div`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 10px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.7;
  margin-bottom: 6px;
  color: ${COLORS.COLOR_DARK};
  text-align: left;
  & span {
    padding: 0px;
  }
`;

const BodyContainer = styled.div`
  width: 98%;
  border-radius: 10px;
  background-color: white;
  border: solid 0.5px #e1e3ee;
  padding: 4px 5px 14px 5px;
`;

const StyledForm = styled.div``;
