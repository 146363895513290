import React from "react";
import styled from "styled-components";
import theme from "../../../../assets/theme";

export default function SearchBar({
  placeHolderText,
  inputText,
  setInputText,
  backgroundColor = "#f4f6ff",
  width = "200px",
}) {
  return (
    <div className="input-icons">
      <div className="search-icons">
        <SearchIcon
          src={require("../../../../assets/images/searchBar.svg")}
          alt="search_icon"
        />
      </div>
      <StyledInput
        placeholder={placeHolderText}
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        backgroundColor={backgroundColor}
        width={width}
      />
    </div>
  );
}

const SearchIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const StyledInput = styled.input`
  width: ${({ width }) => width};
  height: 39px;
  border-radius: 5px;
  border: solid 1px #e1e3ee;
  background-color: ${({ backgroundColor }) => backgroundColor};
  outline: none;
  font-size: 14px;
  font-weight: 600;
  color: #acb1c2;
  font-family: ${theme.fonts.primaryFontRegular};
  padding-left: 35px;
  &::placeholder {
    color: #acb1c2;
  }
`;
