import React, { Fragment } from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import OrderDictation from "../../common/OrederDictationCommon/LiveDictationCommon";

const ActionBar = ({
  actions = [],
  breadcrumbs = [],
  onBreadcrumbClick = () => {},
}) => {
  return (
    <ActionBarContainer>
      <Breadcrumbs>
        {breadcrumbs.map((breadcrumb, index) => (
          <Fragment key={`breadcrumb-${breadcrumb}-${index}`}>
            <Breadcrumb onClick={() => onBreadcrumbClick(breadcrumbs[index])}>
              {breadcrumb}
            </Breadcrumb>
            {index < breadcrumbs.length - 1 && <Breadcrumb>{">"}</Breadcrumb>}
          </Fragment>
        ))}
      </Breadcrumbs>
      <ActionButtonContainer>
        <OrderDictation />
      </ActionButtonContainer>
    </ActionBarContainer>
  );
};

export default ActionBar;

const ActionBarContainer = styled.div`
  border-bottom: 1px solid ${COLORS.BORDER_GREY};
  padding: 0px 53px;
  display: flex;
  align-items: center;
  height: 55px;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Breadcrumb = styled.div`
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  margin-right: 4px;
  cursor: pointer;
  color: ${COLORS.COLOR_DARK};
  user-select: none;
`;
