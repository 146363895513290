import theme from "../../assets/theme";
import React from "react";
import {
  withStyles,
  TextField,
  MenuItem,
  Checkbox,
  makeStyles,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import COLORS from "../../assets/Colors";
import styled, { css } from "styled-components";
import MuiPhoneNumber from "material-ui-phone-number";

export const StyledInput = {
  root: {
    width: "100%",
    fontSize: "16px",
    color: `${COLORS.COLOR_DARK} !important`,
    fontFamily: `${theme.fonts.primaryFontSemiBold}`,
    // background: COLORS.INPUT_BACKGROUND,
    "& .MuiPickersToolbar-toolbar": {
      backgroundColor: COLORS.BTN_GREEN
    },
    "& label": {
      fontFamily: `${theme.fonts.primaryFontSemiBold}`
    },
    "& label.Mui-focused": {
      color: COLORS.COLOR_DARK,
      fontFamily: `${theme.fonts.primaryFontSemiBold}`,
      border: `1px solid ${COLORS.INPUT_BORDER}`
    },
    "& input": {
      letterSpacing: 0.1,
      fontSize: "16px",
      color: `${COLORS.COLOR_DARK} !important`,
      fontFamily: `${theme.fonts.primaryFontSemiBold}`
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: COLORS.INPUT_BACKGROUND,
      height: 50,
      "& fieldset": {
        borderRadius: "6px",
        border: `1px solid ${COLORS.INPUT_BORDER}`
      },
      "&.Mui-focused fieldset": {
        borderColor: COLORS.INPUT_BORDER
      }
    },
    "& .MuiFormHelperText-root": {
      position: "absolute",
      bottom: -17,
      left: -10,
      fontFamily: theme.fonts.primaryFontSemiBold,
      fontSize: 10
    },
    "& .MuiSelect-select": {
      fontSize: "16px",
      fontFamily: theme.fonts.primaryFontSemiBold
    },
    "& .MuiSelect-outlined": {
      color: COLORS.COLOR_DARK
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "& .MuiMenuItem-root": {
      fontSize: "16px",
      fontFamily: theme.fonts.primaryFontSemiBold
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: COLORS.INPUT_BORDER,
      border: `1px solid ${COLORS.INPUT_BORDER}`
    }
  }
};

const StyledMenuItem = withStyles({
  root: {
    fontFamily: theme.fonts.primaryFontSemiBold,
    color: COLORS.COLOR_DARK
  },
  selected: {
    fontFamily: theme.fonts.primaryFontSemiBold,
    color: COLORS.COLOR_DARK
  }
})(MenuItem);

export const CustomInputField = withStyles(StyledInput)(TextField);
export const CustomMobileInput = withStyles(StyledInput)(MuiPhoneNumber);
export const CustomDatePicker = withStyles(StyledInput)(DatePicker);
export const CustomTimePicker = withStyles(StyledInput)(TimePicker);

export { StyledMenuItem };

const CustomCheckBoxStyle = {
  root: {
    "& .MuiSvgIcon-root": {
      fontSize: `22px !important`
    }
  },
  checked: {
    color: `${COLORS.BTN_GREEN} !important`
  }
};

export const CustomCheckBox = withStyles(CustomCheckBoxStyle)(Checkbox);

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: COLORS.PRIMARY_WHITE,
    boxShadow: "none",
    width: "77px",
    height: "30px",
    color: COLORS.BTN_GREEN,
    textTransform: "Capitalize",
    border: "solid 1px #00838c",
    fontFamily: theme.fonts.primaryFontBold,
    fontSize: 14
  },
  radioGroup: {
    "& .MuiRadio-colorPrimary.Mui-checked": {
      color: COLORS.BTN_GREEN
    },
    "& .MuiFormControlLabel-root": {
      cursor: "default"
    },
    "& .MuiTypography-root": {
      cursor: "pointer"
    }
  },
  formLabel: {
    fontFamily: theme.fonts.primaryFontRegular,
    color: COLORS.COLOR_DARK,
    marginRight: 42
    // minWidth: 98,
  },
  radioButton: {
    color: COLORS.BTN_GREEN
  }
}));

export function CustomFileUpload({
  width,
  file = {},
  setFile = () => {},
  accept = ".pdf",
  fileUrl,
  multiple,
  ...props
}) {
  const classes = useStyles();
  const setFileName = file => {
    if (file) {
      const fileName = decodeURI(file);
      return fileName.length < 30
        ? fileName
        : fileName.substring(0, 30) + "...";
    } else {
      return "Select a file";
    }
  };
  return (
    <FileContainer {...{ width }} {...props}>
      {file?.name ? (
        file?.name === "Multiple" ? (
          <FileName style={{ cursor: "pointer" }}>
            {setFileName(file?.name)}
          </FileName>
        ) : (
          <FileName
            style={{ cursor: "pointer" }}
            onClick={() => window.open(fileUrl)}
          >
            {setFileName(file?.name)}
          </FileName>
        )
      ) : (
        <FileName>{setFileName(file?.name)}</FileName>
      )}
      <Button
        disableElevation
        className={classes.button}
        variant="contained"
        component="label"
      >
        Browse
        <input
          onChange={e =>
            e?.target?.files && multiple
              ? setFile(e.target.files)
              : setFile(e.target.files[0])
          }
          onClick={e => (e.target.value = null)}
          type="file"
          style={{ display: "none" }}
          accept={accept}
          multiple={multiple}
        />
      </Button>
      {props.error && <ErrorText>{props.error}</ErrorText>}
    </FileContainer>
  );
}

export function CustomMultipleFileUpload({
  width,
  file = {},
  setFile = () => {},
  accept = ".pdf",
  dark = false,
  setUrl,
  fileUrl,
  ...props
}) {
  const setFileName = file => {
    if (file) {
      const fileName = decodeURI(file);
      return fileName.length < 30
        ? fileName
        : fileName.substring(0, 30) + "...";
    } else {
      return "Select a file";
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <FileContainerMultiple {...{ width, dark }} {...props}>
        {file?.name ? (
          <FileName
            style={{ cursor: "pointer" }}
            onClick={() => window.open(fileUrl)}
          >
            {setFileName(file?.name)}
          </FileName>
        ) : (
          <FileName>{setFileName(file?.name)}</FileName>
        )}
        {props.error && <ErrorText>{props.error}</ErrorText>}
      </FileContainerMultiple>
    </div>
  );
}

const ErrorText = styled.span`
  position: absolute;
  font-size: 10px;
  color: #f44336;
  font-family: openSans-SemiBold, sans-serif;
  bottom: -16px;
  left: 3px;
`;

export function CustomRadioGroup({
  values = {},
  name = "",
  handleChange = () => {},
  radioInputs = [],
  direction = "row",
  checkedIcon = null,
  handleFocus = () => {},
  handleBlur = () => {}
}) {
  const classes = useStyles();
  return (
    <RadioGroup
      className={classes.radioGroup}
      style={{ flexDirection: direction }}
      aria-label={name}
      name={name}
      value={values[name]}
      onChange={handleChange}
      onFocus={() => handleFocus(values[name])}
      onBlur={handleBlur}
    >
      {radioInputs.map((radio, key) => (
        <FormControlLabel
          {...{ key }}
          classes={{ label: classes.formLabel }}
          value={radio.value}
          control={
            <Radio
              color="primary"
              autoFocus={radio.autofocus}
              icon={
                <CheckedIconContainer>
                  <UnCheckIconFiller />
                </CheckedIconContainer>
              }
              checkedIcon={
                <CheckedIconContainer>
                  <CheckIconFiller />
                </CheckedIconContainer>
              }
              classes={{
                colorPrimary: classes.radioButton
              }}
            />
          }
          label={radio.label}
        />
      ))}
    </RadioGroup>
  );
}

const FileContainer = styled.div`
  position: relative;
  width: ${({ width }) => (width ? width + "px" : "436px")};
  background-color: ${COLORS.PRIMARY_WHITE};
  height: 50px;
  border-radius: 6px;
  border: solid 0.5px ${COLORS.INPUT_BORDER};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
  padding: 10px 12px;
  ${({ error }) =>
    error &&
    css`
      border: solid 0.5px #f44336;
    `}
`;

const FileName = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  letter-spacing: 0.1px;
  color: ${COLORS.COLOR_DARK};
`;

const CheckedIconContainer = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid ${COLORS.BTN_GREEN};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckIconFiller = styled.div`
  width: 14px;
  height: 14px;
  background: ${COLORS.BTN_GREEN};
  border-radius: 50%;
`;

const UnCheckIconFiller = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;

export const CustomCheckbox = ({ variant = "lg", ...props }) => (
  <Checkbox
    color={"primary"}
    icon={
      <img
        style={variant === "sm" ? { width: 14, height: 14 } : {}}
        src={require("../../assets/images/checkbox.svg")}
        alt="check"
      />
    }
    checkedIcon={
      <img
        style={variant === "sm" ? { width: 14, height: 14 } : {}}
        src={require("../../assets/images/checkBoxSelected.svg")}
        alt="check"
      />
    }
    {...props}
  />
);

const FileContainerMultiple = styled.div`
  position: relative;
  width: ${({ width }) => (width ? width + "px" : "375px")};
  background-color: ${({ dark }) =>
    dark ? COLORS.INPUT_BACKGROUND : COLORS.PRIMARY_WHITE};
  height: 50px;
  border-radius: 6px;
  border: solid 0.5px ${COLORS.INPUT_BORDER};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 12px;
  ${({ error }) =>
    error &&
    css`
      border: solid 0.5px #f44336;
    `}
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;
