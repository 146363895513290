import React from "react";
import { AccVerifyContainer } from "../AccoutVerificationPage/Styles";
import { Center } from "../../../styles/component/style";
import { AccountVerification } from "../../internal/AccountVerification/AccountVerification";
import { Header } from "../../internal/Header/Header";
import AuthService from "../../../services/AuthService";
import { useSnackbar } from "notistack";
import useUser from "../../../hooks/useUser";
import useLoader from "../../../hooks/useLoader";

const Index = ({ location }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { userObject } = useUser();
  const { setLoader } = useLoader();

  async function resendEmail() {
    try {
      setLoader({ state: true, message: "Resending verification mail..." });
      const payload = { email: userObject.email };
      const response = await AuthService.resendEmail(payload);
      if (response) {
        enqueueSnackbar(
          "Verification mail sent successfully. Please check your Junk Folder/ Promotions Folder incase you don't receive the email",
          {
            variant: "success",
          }
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader({ state: false });
    }
  }

  return (
    <Header>
      <Center>
        <AccVerifyContainer>
          <AccountVerification
            heading="EMAIL NOT VERIFIED"
            timer={0}
            message={`Hey ${userObject.name}, Your email - ${userObject.email} is pending verification. Please check your inbox and click on the email verification link that we’ve sent you.`}
            triggerResent={resendEmail}
            location={location}
          />
        </AccVerifyContainer>
      </Center>
    </Header>
  );
};

export default Index;
