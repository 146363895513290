import moment from "moment";
import React from "react";
import COLORS from "../../../assets/Colors";
import { Images } from "../../../assets/images";
import { parseTimeStamp } from "../../../helpers/functions";
import { Icon, NotificationText, TimeSlot } from "./styles";

const Items = ({ type, message, time }) => {
  const renderIcon = (type) => {
    switch (type) {
      case "PAYMENT":
        return {
          icon: require("../../../assets/images/payments-red.svg"),
          color: COLORS.NOTIFICATION_RED,
        };
      case "OFFER":
        return {
          icon: require("../../../assets/images/message.svg"),
          color: COLORS.NOTIFICATION_ORANGE,
        };
      case "SUCCESS":
        return {
          icon: require("../../../assets/images/done.svg"),
          color: COLORS.NOTIFICATION_GREEN,
        };
      case "UPLOAD":
        return {
          icon: Images.filefloder,
          color: COLORS.NOTIFICATION_BLUE,
        };
      default:
        return {
          icon: Images.filefloder,
          color: COLORS.NOTIFICATION_BLUE,
        };
    }
  };
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Icon bg={renderIcon(type).color}>
          <img
            src={renderIcon(type).icon}
            alt="icons"
            style={{ height: 20, width: 20 }}
          />
        </Icon>
        <NotificationText>{message}</NotificationText>
      </div>
      <TimeSlot>{moment(parseTimeStamp(time)).fromNow()}</TimeSlot>
    </div>
  );
};

export default Items;
