import React, { Fragment, useEffect, useRef, useState } from "react";
import theme from "../../../assets/theme";
import CustomTable from "../../common/CustomTable/CustomTable";
import _ from "lodash";
import queryString from "query-string";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import CaseService from "../../../services/CaseService";
import styled from "styled-components";
import GetCaseModal from "./GetCaseModal";
import Public from "@material-ui/icons/Public";
import { Tooltip } from "@material-ui/core";
import COLORS from "../../../assets/Colors";
import { AssignmentInd, Assignment } from "@material-ui/icons";
import DocumentUploadModal from "./DocumentUploadModal";

async function getdictateOrderContent(query = "") {
  try {
    const response = await CaseService.dictateOrderContent(query);
    return { ...response };
  } catch (error) {
    throw error;
  }
}

const TableView = ({ refresh, setRefresh, setReEdit }) => {
  const [state, setState] = useState({});
  const MTRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const placeholderText = "Search cases";
  const [caseId, setCaseId] = useState();
  const [modal, setModal] = useState({ state: false });
  const [open, setOpen] = useState({ state: false });

  useEffect(() => {
    if (refresh) {
      MTRef.current.onQueryChange("");
      setRefresh(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const columns = [
    {
      field: "id",
      title: "Id",
      sorting: false,
      render: (rowData) => <Bold>{rowData?.id}</Bold>,
      headerStyle: {
        paddingLeft: "40px",
      },
      cellStyle: {
        paddingLeft: "40px",
      },
    },
    {
      field: "title",
      title: "Content",
      sorting: false,
      render: (rowData) => (
        <Bold onClick={() => setReEdit(rowData)}>
          <Tooltip title="Edit Content" placement="right">
            <Assignment style={{ cursor: "pointer", fill: COLORS.BTN_GREEN }} />
          </Tooltip>
        </Bold>
      ),
      cellStyle: {
        paddingLeft: "30px",
      },
    },
    {
      field: "caseId",
      title: "Case ID",
      sorting: false,
      render: (rowData) => (
        <Bold onClick={() => setModal({ state: true, id: rowData?.id })}>
          {rowData?.caseId ? (
            rowData?.caseId
          ) : (
            <Tooltip title="Assign Case ID" placement="right">
              <AssignmentInd
                style={{
                  cursor: "pointer",
                  fill: COLORS.BTN_GREEN,
                  marginRight: "18px",
                }}
              />
            </Tooltip>
          )}
        </Bold>
      ),
      cellStyle: {
        paddingLeft: "30px",
      },
    },
    {
      field: "",
      title: "Publish",
      sorting: false,
      render: (rowData) => (
        <Tooltip title="publish" placement="right">
          {rowData?.caseId ? (
            <Public
              style={{ cursor: "pointer", fill: COLORS.BTN_GREEN }}
              onClick={() => setOpen({ state: true, id: rowData?.id })}
            />
          ) : (
            <Public style={{ cursor: "pointer", fill: COLORS.BTN_GREEN }} />
          )}
        </Tooltip>
      ),
      cellStyle: {
        paddingLeft: "22px",
      },
    },
    {
      field: "created_at",
      title: "Created at",
      sorting: false,
      render: (rowData) => <Bold>{rowData?.created_at}</Bold>,
      cellStyle: {
        paddingLeft: "22px",
      },
    },
  ];

  const data = (query) =>
    new Promise((resolve) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
      };
      // if (query.orderBy?.field) {
      //   params.sort = query.orderBy.field;
      //   params.sortDirection = query.orderDirection;
      // }
      if (query?.search) {
        params.search = query?.search;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      getdictateOrderContent(stringParams)
        .then(async (result) => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
          }
        })
        .catch((error) => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error",
          });
          setState({ data: [], lastPage: 1, page: 1, perPage: 10, total: 0 });
          resolve({
            data: [],
            page: 0,
            total: 0,
          });
        });
    });

  return (
    <Fragment>
      <Padding>
        <CustomTable
          hidePagination={state.lastPage === 1}
          pageSize={state?.data?.length ? state?.data?.length : 10}
          pluralTitle="Dictation"
          singularTitle=""
          {...{
            columns,
            data,
            MTRef,
            placeholderText,
          }}
          noToolbar
          state={state}
        />
      </Padding>
      <GetCaseModal {...{ modal, setModal, caseId, setCaseId, MTRef }} />
      <DocumentUploadModal {...{ open, setOpen, MTRef, setReEdit }} />
    </Fragment>
  );
};

export default TableView;

const Padding = styled.div`
  padding-top: 30px;
  padding-bottom: 50px;
  padding-left: 35px;
  padding-right: 53px;
`;

const Bold = styled.span`
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;