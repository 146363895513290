import React from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";

const TimeLine = React.memo(({ data }) => {
  function formatDateTime(dateTimeStr) {
    const [date, time] = dateTimeStr.split(" ");

    // Parse the date part

    const [day, month, year] = date.split("/");

    // Parse the time part
    let [hours, minutes] = time.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format, handling midnight (0 -> 12)

    // Format the time part

    const formattedTime = `${hours}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;

    return `${day}/${month}/${year} ${formattedTime}`;
  }

  return (
    <TimelineContainer className="timelines-item">
      <div className="timelines-item-content">
        <Time>{formatDateTime(data.time)}</Time>
        <Paragraph>
          <Label>Remarks : </Label>
          <P>{data.remarks ? data.remarks : "-"}</P>
        </Paragraph>
        <Paragraph>
          <Label>Hearing Type : </Label>
          <P>{data.hearingType ? data.hearingType : "-"}</P>
        </Paragraph>
        <Span className="circle" />
      </div>
    </TimelineContainer>
  );
});

export default TimeLine;

const TimelineContainer = styled.div``;

const P = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: #293461;
  margin-left: 5px;
`;
const Time = styled.time`
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: #e1e3ee;
`;

const Span = styled.span``;

const Paragraph = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0px;
`;

const Label = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.7;
  color: #8294a5;
  text-align: left;
`;
