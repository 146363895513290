import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

export const TimeSlot = styled.div`
  position: absolute;
  color: ${COLORS.COLOR_DARK};
  opacity: 0.5;
  font-size: 10px;
  right: 0px;
  bottom: 0px;
  margin-top: 2px;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

export const Icon = styled.div`
  height: 40px;
  width: 40px;
  background: ${(props) => (props.bg ? props.bg : "")};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NotificationText = styled.div`
  width: 230px;
  margin: 0px 12px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  font-weight: 600;
  color: ${COLORS.COLOR_DARK};
  white-space: break-spaces;
`;

export const NotifyIcon = styled.img`
  width: 18px;
  height: 18px;
  object-fit: contain;
  margin-right: 10px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  cursor: default;
`;

export const Count = styled.div`
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 6px;
  background-color: #f4f6ff;
  justify-content: center;
  align-items: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontBold};
  cursor: pointer;
`;

export const IconHolder = styled.div`
  height: 18px;
  width: 18px;
  margin-right: 10px;
  .icon {
    font-size: 18px;
    color: ${COLORS.BTN_GREEN};
  }
`;
