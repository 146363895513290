import React from 'react'
import { Center } from "../../../styles/component/style";
import { Header } from "../../internal/Header/Header";
import { PrimaryCTAButton } from "../../common/Buttons";
import { navigate } from '@reach/router';
import styled from 'styled-components';
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

const Index = ({
  errorCode = 'Error Code : 404',
  errorMessage = 'We cannot find the page you requested for. the page may not available at the moment or have been removed permanently.',
  url = '/',
  buttonLabel = 'Go to Home'
}
) => {
  return (
    <Header hideLinks> 
      <Center>
        <CardWrapper>
          <H2>{errorCode}</H2>
          <Message>{errorMessage}</Message>
          <ButtonWrapper>
            <PrimaryCTAButton 
              style={{width: 412}}
              onClick={ () => navigate(url)}
              >
              {buttonLabel}
            </PrimaryCTAButton>
          </ButtonWrapper>
        </CardWrapper>
      </Center>
    </Header>
  )
}

export default Index

const CardWrapper = styled.div`
  margin-top: 53px;
  width: 500px;
  height: 335px;
  border-radius: 11px;
  background-color: white;
  display: flex;
  flex-direction:column;
  align-items: center;
`
const H2 = styled.h2`
  margin-top: 54px;
  margin-bottom: 35px;
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: 18px;
  color: ${COLORS.COLOR_DARK};
`
const Message = styled.div`
  width: 400px;
  margin-bottom: 55px;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
  line-height: 1.29;
  color: ${COLORS.COLOR_DARK};
  text-align: center;

`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content:center;
  margin-bottom: 55px;

`