import React, { useEffect, useState } from "react";
import { Header } from "../../internal/Header/Header";
import labels from "../../../helpers/labels.json";
import ActionBar from "./OrderDictationActionBar";
import _ from "lodash";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import ReactQuill from "react-quill";
import styled from "styled-components";
import { PrimaryCTAButton } from "../../common/Buttons";
import DictationCommonTable from "../../internal/DictationCommonTable/index";
import useLoader from "../../../hooks/useLoader";
import { getErrorMessage } from "../../../helpers/functions";
import CaseService from "../../../services/CaseService";
import { useSnackbar } from "notistack";
import { sanitize } from "dompurify";
import useDictation from "../../../hooks/useDictation";

const modules = {
  toolbar: [
    [
      { align: "" },
      { align: "center" },
      { align: "right" },
      "bold",
      "italic",
      "underline",
      "blockquote",
    ],
  ],
};

const ESCAPE_HTML_REGEX = /<[^>]+>/g;

const Index = () => {
  const breadcrumbs = [_.startCase(labels.order_dictation)];
  // const [comment, setComment] = useState("");
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [refresh, setRefresh] = useState(false);
  const [reEdit, setReEdit] = useState();
  const { comment, setComment, setReset } = useDictation();

  const onFormSubmit = async (comment) => {
    try {
      setLoader({ state: true, message: "Transcript moved" });
      if (reEdit?.id) {
        const payload = {
          content: comment,
        };
        const id = reEdit?.id;
        const response = await CaseService.dictateOrderOne(payload, id);
        if (response) {
          enqueueSnackbar(response?.message, {
            variant: "success",
          });
        }
      } else {
        const payload = {
          content: comment,
        };
        const response = await CaseService.dictateOrder(payload);
        if (response) {
          enqueueSnackbar(response?.message, {
            variant: "success",
          });
        }
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setComment("");
      setReEdit("");
      setRefresh(true);
      setReset(true);
    }
  };

  useEffect(() => {
    if (reEdit?.id) {
      setComment(reEdit?.content);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reEdit]);

  return (
    <>
      <Header Header showSidebar selectedItem={labels.order_dictation}>
        <ActionBar {...{ breadcrumbs }} />
        <Container>
          <EditorContainer>
            <ReactQuill
              value={sanitize(comment)}
              onChange={(text) => setComment(text)}
              modules={modules}
              placeholder="Record the Proceedings…"
              readOnly={false}
            />
          </EditorContainer>
          <ButtonContainer>
            <PrimaryCTAButton
              style={{ padding: "12px 22px" }}
              className="dictation"
              aria-haspopup="true"
              disabled={!comment?.replace(ESCAPE_HTML_REGEX, "").trim()}
              onClick={() => onFormSubmit(comment)}
            >
              Submit
            </PrimaryCTAButton>
            {/* <div className="dictation">
              <OrderDictation {...{ setComment }} />
            </div> */}
          </ButtonContainer>
        </Container>
        <CommentContainerMain>
          <DictationCommonTable
            {...{ refresh, setRefresh, reEdit, setReEdit }}
          />
        </CommentContainerMain>
      </Header>
    </>
  );
};

export default Index;

const Container = styled.div`
  padding: 34px 43px;
  display: flex;
`;

const ButtonContainer = styled.div`
  width: 144px;
  margin-left: 24px;
  align-self: flex-end;
  position: relative;
  .dictation {
    margin-bottom: 16px;
  }
`;

const EditorContainer = styled.div`
  width: 85%;
  & .rdw-editor-wrapper,
  .ql-editor,
  .ql-container {
    background-color: #f9f9f9;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-family: ${theme.fonts.primaryFontRegular};
  }
  .ql-editor {
    padding: 16px 16px;
    height: 180px;
  }
  & .rdw-editor-toolbar,
  .ql-toolbar {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #f4f6ff;
    border: 1px solid ${COLORS.COLOR_DARK} !important;
    & button {
      padding: 0;
    }
  }
  & .rdw-option-wrapper {
    background-color: transparent;
    border: 1px solid transparent;
    &:hover {
      box-shadow: none;
    }
  }
  & .ql-active {
    box-shadow: none;
    border: 1px solid rgb(200, 210, 220) !important;
  }
  & .rdw-editor-main {
    padding: 0 17px 10px;
    font-family: ${theme.fonts.primaryFontRegular};
  }
  & .public-DraftEditorPlaceholder-root,
  & .quill > .ql-container > .ql-editor.ql-blank::before {
    font-family: ${theme.fonts.primaryFontSemiBold};
    font-size: 14px;
    color: #acb1c2;
    font-style: normal;
  }
  & .blockquote .rdw-option-wrapper {
    content: "";
  }
  & .ql-toolbar.ql-snow {
    padding: 12px 12px 16px;
    .ql-formats button {
      margin-right: 60px;
    }
    .ql-align {
      outline: none;
    }
  }
`;

const CommentContainerMain = styled.div`
  width: 87%;
`;

export const OrderContainer = styled.div`
  padding: 18px;
  border-radius: 7px;
  background-color: ${COLORS.LIGHT_BLUE};
  border: solid 1px ${COLORS.COLOR_DARK};
  margin-bottom: 12px;
  max-width: 80%;
`;

export const Contents = styled.div`
  font-size: 14px;
  word-break: break-word;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontSemiBold};
  margin-top: 18px;
  & strong,
  & bold {
    font-family: ${theme.fonts.primaryFontExtraBold};
  }
`;
