import { Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import CaseService from "../../../services/CaseService";
import styled from "styled-components";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../common/Buttons";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import { CustomCheckbox } from "../../common/FormInputs";
import useLoader from "../../../hooks/useLoader";

const GetCaseModal = ({ modal, setModal, caseId, setCaseId, MTRef }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState();
  const [selected, setSelected] = useState(null);
  const { setLoader } = useLoader();

  useEffect(() => {
    async function getCaseIds() {
      try {
        const response = await CaseService.getCaseIds();
        if (response) {
          setState(response);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    }
    if (modal?.state) {
      getCaseIds();
      setSelected();
    }
  }, [modal?.state]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (index, id) => {
    setSelected((prev) => (index === prev ? null : index));
    setCaseId(id);
  };

  async function onFormSubmit(caseId, id) {
    try {
      setLoader({ state: true, message: `Submitting Transcript...` });
      const payload = {
        caseId: caseId,
      };
      const response = await CaseService.dictateOrderOne(payload, id);
      if (response) {
        enqueueSnackbar(response?.message, { variant: "success" });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      MTRef.current.onQueryChange("");
      setModal({ state: false });
    }
  }

  return (
    <>
      <Modal open={modal?.state} onClose={() => setModal({ state: false })}>
        <DrawerContainer>
          <HeadingContainer>
            <Heading>{"Select Case ID"}</Heading>
            <CloseModal
              onClick={() => setModal({ state: false })}
              src={require("../../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          <FormContainer>
            <div className="docs-listing">
              {state?.cases?.map((item, index) => (
                <CheckboxContainer className="cm_row">
                  <CustomCheckbox
                    key={item?.id}
                    checked={index === selected}
                    onChange={() => handleChange(index, item?.id)}
                  />
                  <div className="cm_name">
                    <div>
                      {item?.id} ({item?.title}){" "}
                      <b style={{ color: COLORS.BOX_DARKGREEN }}>
                        ({item?.resolutionKind})
                      </b>
                    </div>
                  </div>
                </CheckboxContainer>
              ))}
            </div>
            <ButtonContainer>
              <PrimaryOutlinedCTAButton
                style={{ width: "45%" }}
                onClick={(e) => {
                  e.preventDefault();
                  setModal(false);
                }}
              >
                {"Cancel"}
              </PrimaryOutlinedCTAButton>
              <PrimaryCTAButton
                style={{ width: "45%" }}
                onClick={(e) => {
                  e.preventDefault();
                  onFormSubmit(caseId, modal?.id);
                }}
              >
                {"Submit"}
              </PrimaryCTAButton>
            </ButtonContainer>
          </FormContainer>
        </DrawerContainer>
      </Modal>
    </>
  );
};

export default GetCaseModal;

const DrawerContainer = styled.div`
  max-width: 476px;
  min-height: 318px;
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  & .cm_row {
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
    align-items: center;
    & .cm_name {
      font-size: 14px;
      font-weight: 600;
      flex: 1;
      color: ${COLORS.COLOR_DARK};
      font-family: ${theme.fonts.primaryFontSemiBold};
    }
  }
  & .docs-listing {
    overflow: auto;
    height: 216px;
    border: 4px solid transparent;
    border-radius: 8px;
    margin-left: 10px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 45px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const FormContainer = styled.p`
  padding-top: 24px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-around;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;
