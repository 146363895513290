import React, { useState } from "react";

export const DictationXContext = React.createContext();

function DictationXProvider({ children }) {
  const [comment, setComment] = useState();
  const [url, setUrl] = useState();
  const [start, setStart] = useState();
  const [isRecording, setIsRecording] = useState(false);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [transcribedData, setTranscribedData] = useState([]);
  const [mainMediaIdArr, setMainMediaIdArr] = useState({});
  const [mediaIdArr, setMediaIdArr] = useState({});
  const [transcriptionReceived, setTranscriptionReceived] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [recordStatus, setRecordStatus] = useState(false);
  const value = React.useMemo(() => {
    return {
      comment,
      setComment,
      url,
      setUrl,
      start,
      setStart,
      isRecording,
      setIsRecording,
      isTranscribing,
      setIsTranscribing,
      transcribedData,
      setTranscribedData,
      mediaIdArr,
      setMediaIdArr,
      mainMediaIdArr,
      setMainMediaIdArr,
      transcriptionReceived,
      setTranscriptionReceived,
      disabled,
      setDisabled,
      recordStatus,
      setRecordStatus,
    };
  }, [
    comment,
    setComment,
    url,
    setUrl,
    start,
    setStart,
    isRecording,
    setIsRecording,
    isTranscribing,
    setIsTranscribing,
    transcribedData,
    setTranscribedData,
    mediaIdArr,
    setMediaIdArr,
    mainMediaIdArr,
    setMainMediaIdArr,
    transcriptionReceived,
    setTranscriptionReceived,
    disabled,
    setDisabled,
    recordStatus,
    setRecordStatus,
  ]);

  return (
    <DictationXContext.Provider value={value}>
      {children}
    </DictationXContext.Provider>
  );
}

export default DictationXProvider;
