import COLORS from "../../../assets/Colors";
import styled from "styled-components";

export const AccVerifyContainer = styled.div`
  background: ${COLORS.PRIMARY_WHITE};
  border-radius: 11px;
  width: 700px;
  margin: 20px 0px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 600px) {
    width: 90vw;
    padding: 30px 30px;
  }
`;
