import React from "react";
import { AccVerifyContainer } from "./Styles";
import { Center } from "../../../styles/component/style";
import { AccountVerification } from "../../internal/AccountVerification/AccountVerification";
import { Header } from "../../internal/Header/Header";
import AuthService from "../../../services/AuthService";
import { useSnackbar } from "notistack";
import useUser from "../../../hooks/useUser";
import useLoader from "../../../hooks/useLoader";
import { getErrorMessage } from "../../../helpers/functions";
import labels from "../../../helpers/labels.json";

const Index = ({ location, email_token }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { userObject } = useUser();
  const { setLoader } = useLoader();

  async function resendEmail() {
    try {
      setLoader({ state: true, message: "Resending verfication mail..." });
      const payload = { email: userObject.email };
      const response = await AuthService.resendEmail(payload);
      if (response) {
        enqueueSnackbar(
          "Verification mail sent successfully. Please check your Junk Folder/ Promotions Folder incase you don't receive the email",
          {
            variant: "success",
          }
        );
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  return (
    <Header>
      <Center>
        <AccVerifyContainer>
          <AccountVerification
            heading={labels["onboarding.step4"]}
            message={`Hey ${userObject.name} as the final step of becoming part of JustAct we want you to verify your email.
  Please login to your email - ${userObject.email} and click on the verification Link we sent in order to verify`}
            timer={60}
            isTimmerEnabel={true}
            triggerResent={resendEmail}
            location={location}
            email_token={email_token}
          />
        </AccVerifyContainer>
      </Center>
    </Header>
  );
};

export default Index;
