import React from "react";
import logo from "../../../assets/images/JustAct_Logo_TM_V4-01.png";
import styled from "styled-components";
import theme from "../../../assets/theme";
import useCalendar from "../../../hooks/useCalender";
import dayjs from "dayjs";

export default function CalendarHeader() {
  const { monthIndex, setMonthIndex } = useCalendar();
  function handlePrevMonth() {
    setMonthIndex(monthIndex - 1);
  }
  function handleNextMonth() {
    setMonthIndex(monthIndex + 1);
  }
  function handleReset() {
    setMonthIndex(
      monthIndex === dayjs().month()
        ? monthIndex + Math.random()
        : dayjs().month()
    );
  }
  return (
    <Header>
      <Image src={logo} />
      <Heading>Calendar</Heading>
      <Button onClick={handleReset}>Today</Button>
      <button
        style={{ border: "none", backgroundColor: "#ffffff" }}
        onClick={handlePrevMonth}
      >
        <Icon>chevron_left</Icon>
      </button>
      <button
        style={{ border: "none", backgroundColor: "#ffffff" }}
        onClick={handleNextMonth}
      >
        <Icon>chevron_right</Icon>
      </button>
      <Header2>
        {dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM YYYY")}
      </Header2>
    </Header>
  );
}

const Header = styled.div`
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: center;
  @media only screen and (max-width: 1023px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Image = styled.img`
  width: 3rem;
  height: 3rem;
`;

const Heading = styled.h1`
  margin-right: 2.5rem;
  margin-left: 0.8rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: #718096;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

const Button = styled.button`
  cursor: pointer;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: 1.25rem;
  border-radius: 5px;
  border: 1px solid #8294a5;
  color: rgb(60, 64, 67);
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

const Icon = styled.span`
  font-family: "Material Icons";
  display: inline-block;
  font-size: 24px;
  font-weight: normal;
  vertical-align: middle;
  cursor: pointer;
  color: #666;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

const Header2 = styled.h2`
  margin-left: 1rem;
  font-size: 1.25rem;
  color: #718096;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;
