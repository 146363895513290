/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Header3,
  HeadingContainer,
  CardWrapper,
  ContentContainer,
} from "../../../styles/component/style";
import { PrimaryCTAButton } from "../Buttons";
import { Formik } from "formik";
import { signupWithGoogle } from "../../../helpers/validationSchema";
import FormField from "../FormField/FormField";
import _ from "lodash";
import AuthService from "../../../services/AuthService";
import { useSnackbar } from "notistack";
import { navigate } from "@reach/router";
import { getErrorMessage } from "../../../helpers/functions";
import useLoader from "../../../hooks/useLoader";
import labels from "../../../helpers/labels.json";

export const SignupwithGoogle = ({
  heading = "Enter Phone Number",
  inputs = [],
  submitLabel = labels["proceed"],
  disableSubmit = false,
  location,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const { state } = location;

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true, message: "Please wait..." });
      let payload = _.pick(values, ["mobile"]);
      payload.email = state?.profileObj?.email;
      payload.name = state?.profileObj?.name;
      const response = await AuthService.verifications(payload);
      if (response) {
        enqueueSnackbar("OTP Sent Successfully", {
          variant: "success",
        });
        navigate("/otp", {
          state: {
            payload,
            values,
            idToken: state?.idToken,
            profileObj: state?.profileObj,
          },
        });
      }
      console.log(response);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  return (
    <Formik
      initialValues={{
        mobile: state?.payload?.mobile || "",
      }}
      enableReinitialize
      onSubmit={onFormSubmit}
      validationSchema={signupWithGoogle}
      validateOnBlur
      validateOnChange
    >
      {({
        values,
        handleChange,
        errors,
        handleSubmit,
        touched,
        handleBlur,
        setFieldValue,
      }) => (
        <CardWrapper onSubmit={handleSubmit}>
          <HeadingContainer>
            <Header3>{heading}</Header3>
          </HeadingContainer>
          <ContentContainer>
            {inputs.map((input, index) => {
              return (
                <FormField
                  key={index}
                  {...{
                    touched,
                    errors,
                    input,
                    values,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                  }}
                />
              );
            })}
          </ContentContainer>
          <div className="pt20 pb10">
            <PrimaryCTAButton
              type="submit"
              onClick={handleSubmit}
              disabled={disableSubmit}
            >
              {submitLabel}
            </PrimaryCTAButton>
          </div>
        </CardWrapper>
      )}
    </Formik>
  );
};
