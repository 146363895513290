import React from "react";
import "./App.css";
import "./styles/main.scss";
import { BodySection, Layout } from "./styles/component/style";
import AppRouter from "./AppRoutes";
import Providers from "./providers/Providers";
import Loader from "./components/common/Loader/Loader";
import AlertWrapper from "./components/common/Alert/AlertWrapper";
import SessionLogout from "./components/common/SessionLogout";

function App() {
  return (
    <Providers>
      <Layout>
        <BodySection>
          <Loader />
          <AlertWrapper />
          <AppRouter />
        </BodySection>
        <SessionLogout />
      </Layout>
    </Providers>
  );
}

export default App;
