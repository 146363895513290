import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";

export const MessageContainer = styled.div`
  padding: 50px 0px;
  width: 585px;
  text-align: center;
  margin: 0 auto;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
  line-height: 1.64;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  @media (max-width: 600px) {
    width: 100%;
    /* padding: 30px 30px; */
  }
`;
