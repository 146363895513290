import React, { useState } from "react";

export const BundleContext = React.createContext();

const BundleProvider = ({ children }) => {
  const [bundleOpen, setBundleOpen] = useState({
    state: false,
  });

  const value = React.useMemo(() => {
    return {
      bundleOpen,
      setBundleOpen,
    };
  }, [bundleOpen, setBundleOpen]);

  return (
    <BundleContext.Provider value={value}>{children}</BundleContext.Provider>
  );
};

export default BundleProvider;
