import React, { Fragment, useEffect, useState } from "react";
import ActionBar from "./ActionBar";
import _ from "lodash";
import { navigate, useLocation } from "@reach/router";
import styled from "styled-components";
import themes from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import CaseService from "../../../services/CaseService";
import { getErrorMessage } from "../../../helpers/functions";
import TranscriptEditor from "../../transcripts/TranscriptEditor/index";
import useUser from "../../../hooks/useUser";

const TranscriptEdit = (id) => {
  const history = useLocation();
  const location = useLocation();
  const resolutionKind = history.search.replace("?caseType=", "");
  const breadcrumbs = ["Cases", _.startCase(resolutionKind), "Transcript"];
  const transcriptId = location?.state?.id;
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const [data, setData] = useState([
    {
      id: "",
      start: "",
      end: "",
      text: "",
      speaker: "",
      confidence: "",
      transcriptionFlag: "",
    },
  ]);
  const [mediaUrl, setMediaUrl] = useState();
  const [render, setRender] = useState(false);
  const captureText = true;
  const { userObject } = useUser();

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case resolutionKind:
        navigate(`/mediator/cases/${id?.id}?caseType=${resolutionKind}`);
        break;
      case "cases":
        navigate(`/mediator/cases?caseType=${resolutionKind}`);
        break;
      default:
        break;
    }
  };

  async function getLiveTranscription(transcriptId) {
    try {
      setLoader({ state: true, message: "Fetching Transcription..." });
      const response = await CaseService.getLiveTranscription(transcriptId);
      if (response) {
        setData(
          response?.transcriptData?.words?.map((el) => {
            return {
              id: el?.id,
              end: el?.end,
              confidence: el?.confidence,
              text: el?.text ? el?.text : "",
              start: el?.start,
              speaker: el?.speaker,
              transcriptionFlag: el?.transcriptionFlag,
            };
          })
        );
        setMediaUrl(response);
        setRender(true);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  useEffect(() => {
    if (transcriptId) {
      getLiveTranscription(transcriptId);
    } else {
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateTranscriptionData = async (data) => {
    try {
      setLoader({ state: true, message: "Adding Transcript..." });
      const payload = {
        editedTranscriptionData: data,
      };
      const res = await CaseService.updateTranscriptionData(
        payload,
        transcriptId
      );
      if (res?.updated) {
        getLiveTranscription(transcriptId);
      }
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  const actions = [
    {
      text: "Refresh",
      props: {
        onClick: () => getLiveTranscription(transcriptId),
      },
      hidden: false,
    },
    {
      text: "Save",
      props: {
        onClick: () => updateTranscriptionData(data),
      },
      hidden: false,
    },
  ];

  return (
    <Fragment>
      <ActionBar {...{ breadcrumbs, onBreadcrumbClick, actions }} />
      <Container>
        <div>
          {data && (
            <TranscriptEditor
              {...{ transcriptId, data, render, setRender, captureText }}
              mediaUrl={mediaUrl?.audioFileUrl}
              name={userObject?.name}
              role={userObject?.role}
              title={mediaUrl?.title}
              setData={setData}
            />
          )}
        </div>
      </Container>
    </Fragment>
  );
};

export default TranscriptEdit;

export const Container = styled.div`
  padding: 4px 43px;
  display: flex;
  flex-direction: column;
`;

export const HeadingContainer = styled.span`
  margin-bottom: 31px;
  font-family: ${themes.fonts.primaryFontSemiBold};
  font-size: ${(props) => (props.size ? props.size : "13px")};
  color: ${COLORS.BTN_GREEN};
  // text-transform: uppercase;
  cursor: pointer;
  & .topSection {
    background: ${COLORS.BOX_DARKGREEN};
  }
`;
