export const states = [
  { label: "Select State", value: " ", pinStart: [], disabled: true },
  { label: "Andaman and Nicobar", value: "AN", pinStart: [74] },
  { label: "Andhra Pradesh", value: "AP", pinStart: [50, 51, 52, 53] },
  { label: "Arunachal Pradesh", value: "AR", pinStart: [79] },
  { label: "Assam", value: "AS", pinStart: [78] },
  { label: "Bihar", value: "BR", pinStart: [80, 81, 82, 83, 84, 85] },
  { label: "Chandigarh", value: "CG", pinStart: [16] },
  { label: "Chhattisgarh", value: "CH", pinStart: [45, 46, 47, 48, 49] },
  { label: "Dadra and Nagar Haveli", value: "DH", pinStart: [39] },
  { label: "Daman and Diu", value: "DD", pinStart: [36, 39] },
  { label: "Delhi", value: "DL", pinStart: [11] },
  { label: "Goa", value: "GA", pinStart: [40] },
  { label: "Gujarat", value: "GJ", pinStart: [36, 37, 38, 39] },
  { label: "Haryana", value: "HR", pinStart: [11, 12, 13] },
  { label: "Himachal Pradesh", value: "HP", pinStart: [17] },
  { label: "Jammu and Kashmir", value: "JK", pinStart: [18, 19] },
  { label: "Jharkhand", value: "JH", pinStart: [80, 81, 82, 83, 84, 85] },
  { label: "Karnataka", value: "KA", pinStart: [56, 57, 58, 59] },
  { label: "Kerala", value: "KL", pinStart: [67, 68, 69] },
  { label: "Ladakh", value: "LA", pinStart: [18, 19] },
  { label: "Lakshadweep", value: "LD", pinStart: [68] },
  { label: "Madhya Pradesh", value: "MP", pinStart: [45, 46, 47, 48, 49] },
  { label: "Maharashtra", value: "MH", pinStart: [40, 41, 42, 43, 44] },
  { label: "Manipur", value: "MN", pinStart: [79] },
  { label: "Meghalaya", value: "ML", pinStart: [79] },
  { label: "Mizoram", value: "MZ", pinStart: [79] },
  { label: "Nagaland", value: "NL", pinStart: [79] },
  { label: "Odisha", value: "OR", pinStart: [75, 76, 77] },
  { label: "Puducherry", value: "PY", pinStart: [53, 60, 67] },
  { label: "Punjab", value: "PB", pinStart: [14, 15, 16] },
  { label: "Rajasthan", value: "RJ", pinStart: [30, 31, 32, 33, 34] },
  { label: "Sikkim", value: "SK", pinStart: [73] },
  { label: "Tamil Nadu", value: "TN", pinStart: [60, 61, 62, 63, 64] },
  { label: "Telangana", value: "TS", pinStart: [50, 51, 52, 53] },
  { label: "Tripura", value: "TR", pinStart: [79] },
  {
    label: "Uttar Pradesh",
    value: "UP",
    pinStart: [20, 21, 22, 23, 24, 25, 26, 27, 28],
  },
  {
    label: "Uttarakhand",
    value: "UK",
    pinStart: [20, 21, 22, 23, 24, 25, 26, 27, 28],
  },
  {
    label: "West Bengal",
    value: "WB",
    pinStart: [70, 71, 72, 73, 74],
  },
];

export const MAX_ROUND_TO_START = 4; //Config value for maximum round restirction
export const MAX_ROUND_TO_END = 5; //Config value for maximum round restirction

export const NOTIFICATION_INTERVAL = 5000; // Config value for notification interval

export const NOTIFICATION_ACTIVITY = {
  claimantRequestRegistrationFee: {
    type: "PAYMENT",
  },
  claimantReviewCounterClaim: {
    type: "OFFER",
  },
  respondentReviewCounterClaim: {
    type: "OFFER",
  },
  caseManagerReviewCounterClaim: {
    type: "OFFER",
  },
  mediatorReviewCounterClaim: {
    type: "OFFER",
  },
  claimantRequestNegotiationSuccessFee: {
    type: "PAYMENT",
  },
  respondentRequestNegotiationSuccessFee: {
    type: "PAYMENT",
  },
  caseManagerSummaryUpload: {
    type: "UPLOAD",
  },
  caseManagerAgreementUpload: {
    type: "UPLOAD",
  },
  mediatorReviewDocumentUpload: {
    type: "UPLOAD",
  },
};

/**
 * This the Case Life cycle for CLaimant
 */

export const AWAITING_RESPONDANT = "awaitingRespondant";
export const RESPONDANT_ONBOARDED = "respondantOnboarded";
export const AWAITING_RESPONDANT_QUIT = "caseclosedByCaseManager";

export const CLAIMENT_STR = "claimant";

/* addition Case Lifecycle status change */

export const ARBITRATION_REFERENCE = "arbitrationReference";
export const NOTICE_TO_ARBITRATE = "noticeToArbitrate";
export const APPOINTMENT_OF_ARBITRATOR = "appointmentOfArbitrator";
export const ACCEPTANCE_BY_ARBITRATOR = "acceptanceByArbitrator";
export const FIRST_NOTICE_FIRST_HEARING_INTIMATION = "firstHearingIntimation";
export const FILING_STATEMENT_OF_CLAIM = "filingStatementofClaim";
export const FILING_OF_SECTION_SEVENTEEN = "filingofSection17";
export const SECTION_SEVENTEEN_ORDER_PASSED = "section17OrderPassed";
export const FILING_OF_STATEMENT_OF_DEFENCE = "filingofStatementofDefence";
export const CROSSEXAMINATION_CLAIMANT_WITNESS =
  "crossExaminationClaimantWitness";
export const CROSSEXAMINATION_RESPONDENT_WITNESS =
  "crossExaminationRespondentWitness";
export const ARGUMENT = "arguments";

export const PAYMENT_STATUS = {
  caseRegistration: "Case Registration Fee",
  caseNegotiationSuccess: "Negotiation Agreement Fee",
  claimantPaidNegotiationSuccessFee: "Negotiation Agreement Fee",
  respondentPaidNegotiationSuccessFee: "Negotiation Agreement Fee",
};

export const PAYMENT_STATUS_CART = {
  pendingRegistrationFee: {
    value: "Case Registration Fee",
    amount: 750,
    feeKind: "caseRegistration",
  },
  negotiationReached: {
    value: "Negotiation Agreement Fee",
    amount: 550,
    feeKind: "caseNegotiationSuccess",
  },
  claimantPaidNegotiationSuccessFee: {
    value: "Negotiation Agreement Fee",
    amount: 550,
    feeKind: "caseNegotiationSuccess",
  },
  respondentPaidNegotiationSuccessFee: {
    value: "Negotiation Agreement Fee",
    amount: 550,
    feeKind: "caseNegotiationSuccess",
  },
};

/**Constans for pending Action */
export const PAYMENT_PENDING = [
  "claimantRequestRegistrationFee",
  "claimantRemindRequestRegistrationFee",
  "respondentRequestNegotiationSuccessFee",
  "claimantRequestNegotiationSuccessFee",
];

/**
 * @description Sub Stages
 */
export const DRAFT = "draft";
export const PENDING_REGISTRATION_FEE = "pendingRegistrationFee";
export const WAITING_FOR_CASE_APPROVAL = "waitingForCaseApproval";
export const AWAITING_RESPONDANT_CASE_ACCEPTANCE =
  "awaitingRespondentAcceptance";
export const WAITING_FOR_ARBITRATION_CONFIRMATION =
  "waitingForArbitratorConfirmation";
export const NEGOTIATION_ONGOING = "negotiationOngoing";
export const NEGOTIATION_REACHED = "negotiationReached";
export const CLAIMENT_PAID_NEGOTIATION = "claimantPaidNegotiationSuccessFee";
export const RESPONDENT_PAID_NEGOTIATION =
  "respondentPaidNegotiationSuccessFee";
export const BOTH_PAID_NEGOTIATION = "bothPaidNegotiationSuccessFee";
export const NEGOTIATION_QUIT_BY_CLAIMENT = "quitByClaimant";
export const NEGOTIATION_QUIT_BY_CASEMANAGER = "closedByCaseManager";
export const NEGOTIATION_QUIT_BY_RESPONDENT = "quitByRespondent";
export const ARBITRATION_NOMINATED = "arbitrationNominated";
export const ARBITRATION_CONFIRMED = "arbitrationConfirmed";
export const FIRST_NOTICE_SENT = "firstNoticeSent";
export const STATEMENT_OF_CLAIM_FILED = "statementOfClaimFiled";
export const AWAITING_FOR_SEC_SEVENTEEN_DETAILS = "awaitingForSec17Details";
export const SECTION_SEVENTEEN_PETITION_FILED = "sec17PetitionFiled";
export const SECTION_SEVENTEEN_ORDERED = "sec17Ordered";
export const AWAITING_SECTION_SEVENTEEN_EXECUTION = "awaitingSec17Execution";
export const SECTION_SEVENTEEN_EXTENSION_MEMO_FILED = "sec17ExtensionMemoFiled";
export const SECTION_SEVENTEEN_EXTENSION_MEMO_ORDERED =
  "sec17ExtensionMemoOrdered";
export const FS_ORDERED = "fsOrdered";
export const ATTACHMENT_ORDERED = "attachmentOrdered";
export const GARNISHEE_PROHIBITARY_ORDER = "garnisheeProhibitaryOrder";
export const GARNISHEE_FINAL_ORDER = "garnisheeFinalOrder";
export const SECTION_SEVENTEEN_CLOSED = "sec17Closed";
export const STATEMENT_OF_DEFENCE_FILED = "statementOfDefenceFiled";
export const REJOINDER_FROM_CLAIMNT = "rejoinderfromClaimant";
export const SURREJOINDER_FROM_RESPONDENT = "surrejoinderFromRespondent";
export const SECOND_NOTICE_MOM = "2ndNoticeMOM";
export const EVIDENCE_FILLED_BY_CLAIMANT = "evidenceFiledByClaimant";
export const EVIDENCE_FILLED_BY_RESPONDENT = "evidenceFiledByRespondent";
export const CROSS_EXAMINATION = "crossExamination";
export const ARGUMENTS_BY_CLAIMANT = "argumentsByClaimant";
export const ARGUMENTS_BY_RESPONDENT = "argumentsByRespondent";
export const RESERVED_FOR_AWARD = "reservedForAward";
export const AWARD_PASSED = "awardPassed";
export const RESOLUTION_FAILED = "resolutionFailed";
export const SETTLED_AND_WITHDRAWN = "settledAndWithdrawn";
export const UPDATED_AND_WITHDRAWN = "updatedAndWithdrawn";
export const FIRST_HEARING = "firstHearing";
export const SECOND_HEARING = "secondHearing";
export const THIRD_HEARING = "thirdHearing";
export const FIRST_MOM = "firstMom";
export const COMMENCEMENT_LETTER = "commencementLetter";
export const REFERENCE_LETTER = "referenceLetter";
export const ARBITRATION_INPROGRESS = "arbitrationInprogress";

/**This variable is use for life cycle */

/**
 * @description Negotiation life cycle
 *
 */

export const LIFE_CYCLE = [
  /**
   *@description Mediation/Arbitration Initiated stages
   */

  {
    label: "Mediation/Arbitration Initiated",
    key: DRAFT,
    index: 1,
    enableSelection: false,
    text: "Draft",
    cta: [],
  },
  {
    label: "Mediation/Arbitration Initiated",
    key: PENDING_REGISTRATION_FEE,
    index: 1,
    enableSelection: false,
    text: "Awaiting Payment",
    cta: [],
  },
  {
    label: "Mediation/Arbitration Initiated",
    key: WAITING_FOR_CASE_APPROVAL,
    index: 1,
    enableSelection: false,
    text: "Waiting For Case Approval",
    cta: [],
  },
  {
    label: "Mediation/Arbitration Initiated",
    key: AWAITING_RESPONDANT_CASE_ACCEPTANCE,
    index: 1,
    enableSelection: false,
    text: "Waiting for Respondent",
    cta: [],
  },
  {
    label: "Mediation/Arbitration Initiated",
    key: NOTICE_TO_ARBITRATE,
    index: 1,
    enableSelection: false,
    text: "Notice to Arbitrate",
    cta: [],
  },
  {
    label: "Mediation/Arbitration Initiated",
    key: ARBITRATION_REFERENCE,
    index: 1,
    enableSelection: false,
    text: "Arbitration Referenced",
    cta: [],
  },
  {
    label: "Mediation/Arbitration Initiated",
    key: ARBITRATION_NOMINATED,
    index: 1,
    enableSelection: false,
    text: "Arbitrator Nominated",
    cta: [],
  },
  {
    label: "Mediation/Arbitration Initiated",
    key: ARBITRATION_CONFIRMED,
    index: 1,
    enableSelection: false,
    text: "Arbitrator Confirmed",
    cta: [],
  },
  {
    label: "Mediation/Arbitration Initiated",
    key: WAITING_FOR_ARBITRATION_CONFIRMATION,
    index: 1,
    enableSelection: false,
    text: "Waiting for Arbitration Confirmation",
    cta: [],
  },
  {
    label: "Mediation/Arbitration Initiated",
    key: COMMENCEMENT_LETTER,
    index: 1,
    enableSelection: false,
    text: "Commencement Letter",
    cta: [],
  },
  {
    label: "Mediation/Arbitration Initiated",
    key: REFERENCE_LETTER,
    index: 1,
    enableSelection: false,
    text: "Reference Letter",
    cta: [],
  },

  /**
   *@description Mediation/Arbitration In Progress
   */

  {
    label: "Mediation/Arbitration In Progress",
    key: FIRST_NOTICE_SENT,
    index: 2,
    enableSelection: false,
    text: "First Notice Sent",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: STATEMENT_OF_CLAIM_FILED,
    index: 2,
    enableSelection: false,
    text: "Statement of Claim Filed",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: AWAITING_FOR_SEC_SEVENTEEN_DETAILS,
    index: 2,
    enableSelection: false,
    text: "Awaiting for Sec 17 details",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: SECTION_SEVENTEEN_PETITION_FILED,
    index: 2,
    enableSelection: false,
    text: "Sec 17 Petition Filed",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: SECTION_SEVENTEEN_ORDERED,
    index: 2,
    enableSelection: false,
    text: "Sec 17 Ordered",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: AWAITING_SECTION_SEVENTEEN_EXECUTION,
    index: 2,
    enableSelection: false,
    text: "Awaiting Sec 17 Execution",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: SECTION_SEVENTEEN_EXTENSION_MEMO_FILED,
    index: 2,
    enableSelection: false,
    text: "Sec 17 Extension Memo Filed",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: SECTION_SEVENTEEN_EXTENSION_MEMO_ORDERED,
    index: 2,
    enableSelection: false,
    text: "Sec 17 Extension Memo Ordered",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: FS_ORDERED,
    index: 2,
    enableSelection: false,
    text: "FS Ordered",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: ATTACHMENT_ORDERED,
    index: 2,
    enableSelection: false,
    text: "Attachment Ordered",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: GARNISHEE_PROHIBITARY_ORDER,
    index: 2,
    enableSelection: false,
    text: "Garnishee Prohibitary Order",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: GARNISHEE_FINAL_ORDER,
    index: 2,
    enableSelection: false,
    text: "Garnishee Final Order",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: SECTION_SEVENTEEN_CLOSED,
    index: 2,
    enableSelection: false,
    text: "Sec 17 Closed",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: STATEMENT_OF_DEFENCE_FILED,
    index: 2,
    enableSelection: false,
    text: "Statement of Defence Filed",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: REJOINDER_FROM_CLAIMNT,
    index: 2,
    enableSelection: false,
    text: "Rejoinder from Claimant",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: SURREJOINDER_FROM_RESPONDENT,
    index: 2,
    enableSelection: false,
    text: "Surrejoinder From Respondent",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: SECOND_NOTICE_MOM,
    index: 2,
    enableSelection: false,
    text: "2nd Notice MOM",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: EVIDENCE_FILLED_BY_CLAIMANT,
    index: 2,
    enableSelection: false,
    text: "Evidence Filed by Claimant",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: EVIDENCE_FILLED_BY_RESPONDENT,
    index: 2,
    enableSelection: false,
    text: "Evidence Filed by Respondent",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: CROSS_EXAMINATION,
    index: 2,
    enableSelection: false,
    text: "Cross Examination",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: ARGUMENTS_BY_CLAIMANT,
    index: 2,
    enableSelection: false,
    text: "Arguments by Claimant",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: ARGUMENTS_BY_RESPONDENT,
    index: 2,
    enableSelection: false,
    text: "Arguments by Respondent",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: NEGOTIATION_ONGOING,
    index: 2,
    enableSelection: false,
    text: "Under Resolution",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: FIRST_HEARING,
    index: 2,
    enableSelection: false,
    text: "1st Hearing",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: SECOND_HEARING,
    index: 2,
    enableSelection: false,
    text: "2nd Hearing",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: THIRD_HEARING,
    index: 2,
    enableSelection: false,
    text: "3rd Hearing",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: FIRST_MOM,
    index: 2,
    enableSelection: false,
    text: "1st MOM",
    cta: [],
  },
  {
    label: "Mediation/Arbitration In Progress",
    key: ARBITRATION_INPROGRESS,
    index: 2,
    enableSelection: false,
    text: "Arbitration Inprogress",
    cta: [],
  },

  /**
   * @description Mediation/Arbitration Completed
   */

  {
    label: "Mediation/Arbitration Completed",
    key: NEGOTIATION_REACHED,
    index: 3,
    enableSelection: false,
    text: "Negotiation Reached",
    cta: [],
  },
  {
    label: "Mediation/Arbitration Completed",
    key: CLAIMENT_PAID_NEGOTIATION,
    index: 3,
    enableSelection: false,
    text: "Claimant paid Success Fee",
    cta: [],
  },
  {
    label: "Mediation/Arbitration Completed",
    key: RESPONDENT_PAID_NEGOTIATION,
    index: 3,
    enableSelection: false,
    text: "Respondent paid Success Fee",
    cta: [],
  },
  {
    label: "Mediation/Arbitration Completed",
    key: BOTH_PAID_NEGOTIATION,
    index: 3,
    enableSelection: false,
    text: "Both paid NegotiationFee",
    cta: [],
  },
  {
    label: "Mediation/Arbitration Completed",
    key: RESERVED_FOR_AWARD,
    index: 3,
    enableSelection: false,
    text: "Reserved for Award",
    cta: [],
  },
  {
    label: "Mediation/Arbitration Completed",
    key: AWARD_PASSED,
    index: 3,
    enableSelection: false,
    text: "Award Passed",
    cta: [],
  },

  /**
   * @description Resolution Closed
   */

  {
    label: "Resolution Closed",
    key: NEGOTIATION_QUIT_BY_CLAIMENT,
    index: 4,
    enableSelection: false,
    text: "Quit by Claimant",
    cta: [],
  },
  {
    label: "Resolution Closed",
    key: NEGOTIATION_QUIT_BY_RESPONDENT,
    index: 4,
    enableSelection: false,
    text: "Quit by Respondent",
    cta: [],
  },
  {
    label: "Resolution Closed",
    key: RESOLUTION_FAILED,
    index: 4,
    enableSelection: false,
    text: "Resolution Failed",
    cta: [],
  },
  {
    label: "Resolution Closed",
    key: NEGOTIATION_QUIT_BY_CASEMANAGER,
    index: 4,
    enableSelection: false,
    text: "Closed By CaseManager",
    cta: [],
  },
  {
    label: "Settled & Withdrawn",
    key: SETTLED_AND_WITHDRAWN,
    index: 4,
    enableSelection: false,
    text: "Settled & Withdrawn",
    cta: [],
  },
  {
    label: "Updated & Withdrawn",
    key: UPDATED_AND_WITHDRAWN,
    index: 4,
    enableSelection: false,
    text: "Updated & Withdrawn",
    cta: [],
  },
];

export const occupations = [
  { label: "Locomotive Driver", value: "Locomotive Driver" },
  { label: "Lawyer", value: "Lawyer" },
  { label: "Consultant", value: "Consultant" },
  { label: "Surveyor", value: "Surveyor" },
  { label: "Tradesman", value: "Tradesman" },
  { label: "Engineering technician", value: "Engineering technician" },
  { label: "Scientist", value: "Scientist" },
  { label: "Sales management", value: "Sales management" },
  { label: "Drafter", value: "Drafter" },
  { label: "Chef", value: "Chef" },
  { label: "Aviator", value: "Aviator" },
  { label: "Computer operator", value: "Computer operator" },
  { label: "Official", value: "Official" },
  { label: "Flight engineer", value: "Flight engineer" },
  { label: "Chartered Accountant", value: "Chartered Accountant" },
  { label: "Secretary", value: "Secretary" },
  { label: "Professor", value: "Professor" },
  { label: "Model", value: "Model" },
  { label: "Comedian", value: "Comedian" },
  { label: "Civil servant", value: "Civil servant" },
  { label: "Technical writer", value: "Technical writer" },
  { label: "Employed", value: "Employed" },
  { label: "Self-Employed", value: "Self-Employed" },
  { label: "Others", value: "Others" },
];

export const caseDocumentType = [
  { label: "Select Document Type", value: "", disabled: true },
  {
    label: "Notices, Claims & Counterclaims",
    value: "Notices, Claims & Counterclaims",
  },
  { label: "Petitions", value: "Petitions" },
  { label: "Evidence & Arguments", value: "Evidence & Arguments" },
  { label: "Details of Proceedings", value: "Details of Proceedings" },
  { label: "Orders & Minutes", value: "Orders & Minutes" },
  { label: "Agreements", value: "Agreements" },
  { label: "Others", value: "Others" },
];

export const caseVisibilityType = [
  { label: "Select Visibility Type", value: "", disabled: true },
  { label: "Share with all parties and CM", value: "all" },
  { label: "Share with CM", value: "others" },
  { label: "Make private", value: "me" },
];

export const TextType = [
  { label: "Select Type", value: "", disabled: true },
  {
    label: "Question",
    value: "Question",
  },
  { label: "Answer", value: "Answer" },
  { label: "Statement", value: "Statement" },
  { label: "Order", value: "Order" },
];

export const documentTypes = [
  { category: "Agreement", name: "Contract" },
  { category: "Agreement", name: "Loan agreement" },
  { category: "Agreement", name: "Agreement" },
  { category: "Agreement", name: "Guarantee Letter" },
  { category: "Agreement", name: "Personal Guarantee Letter" },
  { category: "Notice", name: "Legal Notice" },
  { category: "Notice", name: "Reposession Notice Intimation Letter" },
  { category: "Notice", name: "First Notice" },
  { category: "Notice", name: "Second Notice" },
  { category: "Notice", name: "Third Notice" },
  { category: "Notice", name: "Notice" },
  { category: "Settlement Documents", name: "Settlement Working Sheet" },
  { category: "Settlement Documents", name: "Settlement Of account" },
  { category: "Settlement Documents", name: "Shortfall Letter" },
  { category: "Settlement Documents", name: "Paper Publication" },
  { category: "Settlement Documents", name: "Tax Invoice" },
  { category: "Settlement Documents", name: "Offer Letter" },
  { category: "Administration Letter", name: "Reference Letter" },
  { category: "Administration Letter", name: "Arb Appointment Letter" },
  { category: "Administration Letter", name: "Claim Statement" },
  { category: "Administration Letter", name: "Counter Claim" },
  { category: "Administration Letter", name: "Rejoinder" },
  {
    category: "Administration Letter",
    name: "Rejoinder Submission Intimation Letter",
  },
  { category: "Administration Letter", name: "Minutes Of Meeting" },
  { category: "Orders And Awards", name: "Furnish Security Order-sec.17" },
  { category: "Orders And Awards", name: "Sec17 Order Attachment" },
  { category: "Orders And Awards", name: "Sec17 Order Commisioner" },
  { category: "Orders And Awards", name: "Sec17 Order Garnishee" },
  { category: "Orders And Awards", name: "Sec17 Order Receiver" },
  { category: "Orders And Awards", name: "Extension order" },
  { category: "Orders And Awards", name: "Termination Order" },
  { category: "Orders And Awards", name: "Arbitration Award" },
  { category: "Orders And Awards", name: "Orders And Awards" },
  { category: "Orders And Awards", name: "Others" },
  { category: "Petitions", name: "Sec17 Petition Attachment " },
  { category: "Petitions", name: "Sec17 Petition Commisioner" },
  { category: "Petitions", name: "Sec17 Petition Garnishee" },
  { category: "PETITIONS", name: "Sec17 Petition Receiver" },
  { category: "Evidences And Arguments", name: "Evidence Letter" },
  { category: "Evidences And Arguments", name: "Arguments" },
];
