import React, { useEffect, useState } from "react";
import useUser from "../../../hooks/useUser";

const SessionLogout = () => {
  const { setToken } = useUser();
  const timeOutInterval = 15 * 60; //  15 mins in milliseconds
  const [timer, setTimer] = useState(timeOutInterval);
  const startTime = timeOutInterval;

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);

    const resetTimeout = () => {
      setTimer(startTime);
    };

    const events = ["mousemove", "mousedown", "click", "scroll", "keypress"];

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }
    if (timer === 0) {
      setToken("logged_out");
    }
    return () => {
      clearInterval(myInterval);
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
      }
    };
  }, [timer]); // eslint-disable-line react-hooks/exhaustive-deps

  return <></>;
};

export default SessionLogout;
