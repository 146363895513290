import React, { useEffect } from "react";
import { Redirect, navigate } from "@reach/router";
import useUser from "../hooks/useUser";
import _ from "lodash";
import { checkKeys } from "../helpers/functions";

export default function ProtectedRoute({ ...props }) {
  const { userObject } = useUser();
  useEffect(() => {
    if (!_.isEmpty(userObject)) {
      if (!userObject.emailVerified) {
        navigate("/not-verifed");
      }
      if (checkKeys(userObject)) {
        navigate("/create-profile");
      }
    }
  }, [userObject]);
  return localStorage.getItem("auth_token") ? (
    props.children
  ) : (
    <Redirect to="/" noThrow />
  );
}
